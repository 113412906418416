.modal {
  .miyosmart-pop-up-container {
    border-radius: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 640px !important;
    transform: translate(-50%, -50%) !important;

    .miyosmart-pop-up-content {
      position: relative;
      padding: 15px;
      width: 100% !important;
      min-width: 320px;
      max-width: 500px;
      border-radius: 10px;
      left: auto;
      display: flex;
      flex-direction: column !important;
      background-color: $c-white !important;

      .miyosmart-pop-up-header {
        position: absolute;
        right: 15px;
        top: 15px;
        width: 100%;
        padding: 0;
        display: flex;
        justify-content: flex-end;
        border-bottom: 0;
        i {
          color: $c-blue;
          font-size: 20px;
          cursor: pointer;
        }
      }
      .modal-body {
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
