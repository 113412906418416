.about-page{
    .about{
        .about-1{
            width: 100%;

            &__main {
                display: flex;
                flex-direction: row;

                @include max-width($scr-sm) {
                    flex-direction: column;
                }
        
            }

            &__left{  
                background-color: $c-light-orange;
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                @include max-width($scr-sm) {
                    width: 100%;
                }
            }

            &__left-content{   
                width: 75%;
                display: flex; 
                flex-direction: column;
                font-size: $text-size-xxxl;
                font-family: GalanoGrotesqueB;
                color: $c-primary; 
                line-height: 1.3;
                justify-content: center;
                align-items: center;             

                @include max-width($scr-md) {
                    font-size: $text-size-xl;
                }

                @include max-width($scr-sm) {
                    padding-top: 50px;   
                    padding-bottom: 50px;                     
                }
            }

            &__right{          
                width: 50%;

                @include max-width($scr-sm) {
                    width: 100%
                }
            }
        }

        .about-2{
            display: flex;
            flex-direction: row;
            width: 100%;
            background-color: $c-white;
            padding-top: 150px;
            padding-bottom: 120px;

            @include max-width($scr-sm) {
                flex-direction: column-reverse;
                padding: 0px;    
            }

            &__left{
                width: 50%;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                background-image: url(../../../images/aboutBg2.png);

                @include min-width($scr-lg) {
                    width: 100%;
                    display: none;
                    background-image: none;
                }

                @include max-width($scr-sm) {
                    width: 100%;
                    background-image: none;
                    display: none;
                }
            }

            &__left-image{
                display: none;

                @include min-width($scr-lg) {
                    width: 50%;
                    display: inline;
                }
                
                @include max-width($scr-sm) {
                    display: initial;
                    width: 100%;
                }                
            }

            &__right{
                width: 50%;
                background-color: $c-light-orange;    
                display: flex;
                align-items: center;
                justify-content: center;

                @include max-width($scr-sm) {
                    width: 100%;    
                    padding-top: 30px; 
                    padding-bottom: 30px;         
                }
            }

            &__right-container{
                display: flex;
                flex-direction: column;
                width: 75%;
                margin-top: 3vw;
                margin-bottom: 3vw;
            }

            &__right-title{
                font-family: GalanoGrotesqueB;
                font-size: $text-size-l;
                line-height: 1.2;
                margin-bottom: 18px;

                @include max-width($scr-sm) {
                    font-size: $text-size-m;         
                }

            }

            &__right-body{
                font-family: GalanoGrotesqueR;
                font-size: $text-size-body;
                line-height: 1.5;
                margin-bottom: 15px;

                @include max-width($scr-sm) {
                    font-size: $text-size-s;         
                }

            }

            &__right-citation{
                font-family: GalanoGrotesqueEL;
                font-size: $text-size-xxs;
                margin-bottom: 10px;
            }
        }

        .about-3{
            display: flex;
            width: 100%;
            background-color: $c-primary;
            color: $c-white;
            padding-top: 120px;
            padding-bottom: 120px;
            align-items: center;
            justify-content: center;
            text-align: center;
            
            @include max-width($scr-sm) {
                padding-top: 50px;
                padding-bottom: 50px;
            }

            &__main{
                display: flex;
                width: 75%;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
            }

            &__title{
                font-family: GalanoGrotesqueB;
                font-size: $text-size-l;
                line-height: 1.2;
                margin-bottom: 18px;

                @include max-width($scr-sm) {
                    font-size: $text-size-m;         
                }
            }

            &__sub-title{
                font-family: GalanoGrotesqueR;
                font-size: $text-size-m;
                line-height: 1.5;
                margin-bottom: 80px;

                @include max-width($scr-sm) {
                    font-size: $text-size-s;  
                    margin-bottom: 30px;       
                }
            }

            &__content{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                @include max-width($scr-xs) {
                    flex-direction: column;
                    flex-wrap: nowrap;
                    justify-content: center;
                }
            }

            &__content-item{
                width: 33%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                flex-direction: column;
                margin-top: 15px;

                @include max-width($scr-sm) {
                    width: 50%;
                }

                @include max-width($scr-xs) {
                    width: 100%;
                }
            }

            &__content-item-icon{
                margin-bottom: 15px;

            }

            &__content-item-description{
                font-family: GalanoGrotesqueB;
                font-size: $text-size-body;
                border-radius: 15px;
                background-color: $c-light-orange;
                color: $c-primary;
                width: 80%;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

                @include max-width($scr-sm) {
                    font-size: $text-size-s;         
                }

                @include max-width("891px") {
                    width: 95%;       
                }

                @include max-width($scr-xs) {
                    width: 100%;
                }
            }
        }

        .about-4{
            display: flex;
            width: 100%;
            flex-direction: row;

            @include max-width($scr-sm) {
                flex-direction: column;
            }

            &__left{
                display: flex;
                width: 50%;
                align-items: center;
                justify-content: center;
                background-color: $c-light-orange;

                @include max-width($scr-sm) {
                    width: 100%;
                }
            }

            &__left-content{
                width: 75%;
                margin-top: 7vw;
                margin-bottom: 6vw;
                position: relative;

                @include max-width($scr-sm) {
                    width: 75%;
                    margin-top: 50px;
                    margin-bottom: 50px;
                }
            }

            &__left-content-title{
                font-family: GalanoGrotesqueB;
                font-size: $text-size-l;
                line-height: 1.2;
                margin-bottom: 18px;
                color: $c-primary;
                position: absolute;
                left: -20px;

                @include max-width($scr-sm) {
                    font-size: $text-size-m;         
                }
            }

            &__left-content-body{
                margin-top: 50px;

                @include max-width("928px") {
                    margin-top: 70px;
                }

                @include max-width($scr-sm) {
                    margin-top: 50px;
                }

                @include max-width("349px") {
                    margin-top: 70px;
                }

                ul {
                    list-style: none;
                    margin-top: 0px;
                    margin-bottom: 0px;
                }

                ol {
                    list-style: none;
                    counter-reset: my-awesome-counter;
                    border-left: 2px solid $c-primary;
                    margin-bottom: 0px;
                }

                ol li {
                    position: relative;
                    counter-increment: my-awesome-counter;
                }
                
                ol li::before {
                    position: absolute;
                    content: counter(my-awesome-counter);
                    color: $c-white;
                    font-weight: bold;
                    background-color: $c-primary;
                    width: 40px;
                    height: 40px;
                    text-align: center;
                    align-items: center;
                    left: -60px;
                    padding: 8px;
                }          
            }

            &__left-content-body-title{
                font-family: GalanoGrotesqueB;
                font-size: $text-size-l;
                line-height: 1.2;
                color: $c-primary;

                @include max-width($scr-sm) {
                    font-size: $text-size-m;         
                }
            }

            &__left-content-body-body{
                font-family: GalanoGrotesqueR;
                font-size: $text-size-body;
                line-height: 1.5;
                padding-top: 18px;
                padding-bottom: 15px;

                @include max-width($scr-sm) {
                    font-size: $text-size-s;  
                }
            }

            &__right{
                width: 50%;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                background-image: url(../../../images/aboutBg3.png);

                @include min-width($scr-lg) {
                    width: 100%;
                    display: none;
                    background-image: none;
                }

                @include max-width($scr-sm) {
                    width: 100%;
                    display: none;
                    background-image: none;
                }
            }

            &__right-image{
                display: none;
                
                @include min-width($scr-lg) {
                    width: 50%;
                    display: inline;
                }

                @include max-width($scr-sm) {
                    width: 100%;
                    display: initial;
                }                
            }
        }

        .about-5{
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            text-align: center;

            &__main{
                display: flex;
                flex-direction: column;
                padding-top: 120px;
                width: 75%;
                position: relative;

                @include max-width($scr-lg) {
                    width: 95%;     
                }

                @include max-width($scr-sm) {
                    width: 75%;
                    padding-top: 50px; 
                    padding-bottom: 10px;
                    justify-content: center;
                    align-items: center;   
                }
            }

            &__title{
                font-family: GalanoGrotesqueB;
                font-size: $text-size-l;
                margin-bottom: 30px;

                @include max-width($scr-sm) {
                    font-size: $text-size-m;  
                    margin-bottom: 15px;  
                }
            }

            &__body{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                @include max-width($scr-sm) {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                }
            }

            &__item{
                display: flex;
                flex-direction: column;
                width: 50%;

                @include max-width($scr-sm) {
                    width: 100%;
                    margin-bottom: 20px;
                }
            }

            &__item-icon{
                display: flex;
                align-items: center;
                justify-content: center;
                height: 140px;
                max-height: 140px;
                flex-grow: 1;
            }

            &__item-title{
                font-family: GalanoGrotesqueB;
                font-size: $text-size-m;
                margin-bottom: 15px;
                margin-top: 15px;

                @include max-width($scr-sm) {
                    font-size: $text-size-s;  
                }
            }

            &__item-content{
                font-family: GalanoGrotesqueR;
                font-size: $text-size-m;
                margin-bottom: 30px;

                @include max-width($scr-sm) {
                    font-size: $text-size-s;  
                    margin-bottom: 15px;  
                }
            }

            &__bottom{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: flex-start;
                margin-top: 50px;

                @include max-width($scr-xs) {
                    flex-direction: column;
                    align-items: center;
                    margin-top: 30px;
                }
            }

            &__bottom-button{
                transform: translate(30px, 8px);
                background-color: $c-primary; 
                font-family: GalanoGrotesqueB;
                max-width: none;
                min-width: 237px;
                padding-left: 20px;
                padding-right: 20px;

                @include max-width($scr-xs) {
                    transform: none;
                    min-width: 0px;
                    margin-bottom: 25px;
                }
            }

            &__about-bee{
                height: 200px;
                width: 181px;
                z-index: 9;

                @include max-width($scr-lg) {
                    height: 190px;
                    width: 161px;
                } 

                @include max-width($scr-sm) {
                    height: 150px;
                    width: 131px;
                }

                @include max-width($scr-xxs) {
                   margin-top: 15px;
                }
            }

            &__citation{
                position: absolute;
                font-family: GalanoGrotesqueEL;
                font-size: $text-size-xxs;
                max-width: 250px;
                text-align: left;
                align-self: flex-end;
                bottom: 100px;
                right: -100px;

                @include max-width($scr-lg) {
                    right: auto;
                }

                @include max-width($scr-md) {
                    position: relative;
                    right: auto;
                    bottom: auto;
                    align-self: center;
                    margin-top: 50px;
                }
            }
        }
    }
}