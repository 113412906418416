.login {
    width: 100%;
    min-height: 100vh;
    min-width: 325px;
    padding: 70px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    background-color: $c-beige;
    overflow-x: hidden;

    @media (max-width: 1014px) {
        padding: 20px 0 0;
    }

    .hoya-logo {
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url("../../images/headLogo.svg");

        @media (min-width: 1014px) {
            position: absolute;
            left: 30px;
            top: 0;
            width: 160px;
            height: 80px;
        }

        @media (max-width: 1014px) {
            position: relative;
            width: 150px;
            height: 50px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &__wrapper {
        position: relative;
        padding: 28px;
        width: 100%;
        z-index: 999;
        border-radius: 20px;
        background-color: $c-white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .signUpCard {
        position: relative;
        width: 100%;
        max-width: 640px;
        margin-top: 45px;

        &:before {
            position: absolute;
            content: "";
            display: block;
            top: -15px;
            right: -15px;
            bottom: 0;
            left: 0;
            border-radius: 20px;
            background-color: $c-blue;
        }

        .title {
            margin: 20px 0;
            font-size: 28px;
            text-align: center;
        }

        .sso-login-container {
            margin-top: 30px;
            color: $c-blue;
            text-align: center;

            p {
                font-size: 14px;
                margin-bottom: 10px;
            }
        }

        .registor-container {
            position: absolute;
            width: 150px;
            bottom: -45px;
            right: -75px;

            @media (max-width: 860px) {
                width: 100px;
                right: 0;
            }

            a {
                .miyobeeLogo {
                    width: 100%;
                }
            }
        }
    }
    .cardInputContainer {
        width: 100%;
        min-width: 320px;
        max-width: 460px;

        .loginForm {
            text-align: center;
            .inputContainer {
                margin: 15px;
                .input_Icon {
                    display: flex;
                    align-items: center;
                    color: $c-blue;
                    position: absolute;
                    i {
                        position: relative;
                        top: 6px;
                        left: 10px;
                        right: 0;
                    }
                }
            }
        }
    }

    .forgot-btn {
        font-weight: 800;
        color: $c-blue;
        text-decoration: none;
    }

    .socialIcons {
        width: 50px;
        height: 50px;
        margin: 0 5px;
        cursor: pointer;
    }
}
