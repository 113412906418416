.testo {
  position: absolute;
  right: 20px;
  top: 100px;
  bottom: 20px;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 460px;
  min-width: 320px;
  z-index: 999999999;
  background-color: $c-beige;

  @media (max-width: 460px) {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .testo-header {
    width: 100%;
    text-align: right;
    i {
      color: $c-blue;
      cursor: pointer;
    }
  }
  .chatlogo {
    height: 40px;
    width: 40px;
  }
  .renderer {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .linecomponent {
    justify-content: space-between;
    display: flex;
    align-items: flex-start;
    width: 100%;
  }
  .chatline {
    width: 88%;
    padding: 10px 18px;
    text-align: left;
    font-size: 14px;
    border: 0;
    color: $c-dark-gray !important;
    border-radius: 8px !important;
    margin: 0px 30px 5px;
    margin-left: 20px;
    background-color: $c-white;
    line-height: 1.2;
  }
  .answerline {
    align-self: flex-start;
    margin: 2.5px;
    background: transparent;
    padding: 5px 15px;
    color: $c-blue;
    border-radius: 8px;
    border: 1px solid $c-blue;
    text-align: left;
    font-size: 14px;
    margin-left: 64px;
    width: 78%;
    line-height: 1.2;
    &:focus,
    &:active {
      color: #ffffff;
      box-shadow: none !important;
      background-color: $c-blue !important;
      border-color: $c-blue !important;
    }
  }
}
