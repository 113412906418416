.register {
  position: relative;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: center;
  background-color: $c-beige;

  .loadingcontainer {
    top: 0;
    position: absolute;
    width: 118%;
    margin-right: 10%;

    height: 100vh;
    z-index: 9999;
    background-color: #afaaaab9;
    left: 0px;
    position: absolute;
    .loading-spinner {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: auto;
      position: absolute;
      top: 20vh;
      left: 46vw;
      @media (max-width: 768px) {
        left: 37vw;
      }
    }
  }

  &__wrapper {
    display: flex;
  }

  &__content {
    min-height: 100vh;
    padding: 35px 0;
    width: 55%;
    display: flex;
    justify-content: flex-end;

    @media (max-width: 768px) {
      width: 100%;
    }

    .hoyalogoContainer {
      width: 180px;
      height: 80px;
      margin: 30px;
      transform: scaleX(-1);

      .hoyalogoStyle {
        width: 100%;
      }
    }

    &:first-child {
      width: 45%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url(../../images/aboutBg1.png);
      transform: scaleX(-1);

      @media (max-width: 768px) {
        display: none !important;
      }
    }

    &:last-child {
      justify-content: center;
      align-items: center;
    }

    .sso-signUp-container {
      margin-top: 20px;
      float: right;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-self: flex-end;

      @media (max-width: 768px) {
        float: left;
      }

      p {
        margin: 0;
        color: $c-blue;
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        margin-bottom: 5px;
      }

      .sso-signUp-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        .socialIcons {
          width: 50px;
          height: 50px;
          margin: 0 10px;
          cursor: pointer;
          @media (max-width: 400px) {
            width: 35px;
            height: 35px;
          }
        }
      }
    }

    .form-container {
      width: 100%;
      max-width: 640px;
      padding: 45px;
      margin: auto;

      .title {
        text-align: left;
      }

      .inputContainer {
        margin: 15px 0;

        .text-input {
          border-radius: 0;
          background-color: $c-beige !important;
        }

        .input-label {
          width: 100%;
          text-align: left;
        }
      }

      .checkbox-container {
        width: 100%;
        text-align: left;

        .checkbox-label {
          margin-left: 10px;
          font-size: 14px;
          font-weight: 800;

          a {
            color: $c-black;
            text-decoration: underline;
          }
        }
      }

      .button-container {
        position: relative;
        z-index: 0;
        display: flex;
        align-items: flex-start;
      }
    }
  }

  &__footer {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__bee {
    width: 100%;
    padding: 15px;
    max-width: 200px;
    min-width: 150px;

    @media (max-width: 460px) {
      display: none;
    }
  }
}

.modal {
  .modalBodyContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;

    .modal-content {
      padding: 10px 20px;
      border-radius: 20px;

      .modalBodyContent {
        margin-right: 20px;
        padding: 25px;

        .title {
          text-align: center;
        }
      }
    }
  }
}

.sign-up {
  width: 100%;
  min-height: 100vh;
  min-width: 325px;
  padding: 70px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background-color: $c-beige;

  @media (max-width: 1014px) {
    padding: 20px 0;
  }

  .hoya-logo {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("../../images/headLogo.svg");

    @media (min-width: 1014px) {
      position: absolute;
      left: 30px;
      top: 0;
      width: 160px;
      height: 80px;
    }

    @media (max-width: 1014px) {
      position: relative;
      width: 150px;
      height: 50px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__wrapper {
    position: relative;
    padding: 35px;
    width: 100%;
    z-index: 999;
    border-radius: 20px;
    background-color: $c-white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .signUpCard {
    position: relative;
    width: 100%;
    max-width: 640px;

    &:before {
      position: absolute;
      content: "";
      display: block;
      top: -15px;
      right: -15px;
      bottom: 0;
      left: 0;
      border-radius: 20px;
      background-color: $c-blue;
    }

    @media (max-width: 1900px) {
      margin-top: 75px;
    }
    @media (max-width: 1600px) {
      margin-top: 55px;
    }

    .title {
      margin: 20px 0;
      text-align: center;
      line-height: 1;
    }

    .sso-login-container {
      margin-top: 50px;
      color: $c-blue;
      text-align: center;

      p {
        margin-bottom: 10px;
      }
    }

    .authenticityCard {
      width: 100%;
      max-width: 360px;
      min-width: 200px;
    }

    .registor-container {
      position: absolute;
      width: 150px;
      width: 150px;
      bottom: -40px;
      right: -80px;

      .miyobeeLogo {
        width: 100%;
      }
    }

    .cardInputContainer {
      width: 100%;
      min-width: 320px;
      max-width: 460px;

      .signUpForm {
        text-align: center;
        .inputContainer {
          margin: 15px;
        }
      }
    }
  }
}
