.layoutContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .verticalFLexContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    .vflex1-sidebar {
      width: 350px;
      height: 100%;
      background-color: $c-blue;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      @media (max-width: 768px) {
        display: none;
      }
      .profilePicAndNameCointainer {
        position: relative;
        height: 100px;
        width: 100%;
        margin-top: 20px;
        padding: 20px;
        .profile-name {
          color: $c-beige;
          font-size: 18px;
          font-family: GalanoGrotesqueB;
          word-break: break-word;
          text-transform: uppercase;
        }
      }
      a {
        text-decoration: none;
      }
      .sidebarElement {
        color: white;
        padding: 20px;
        font-size: 16px;
        display: flex;
        align-items: center;
        cursor: pointer;
        @media (max-width: 960px) {
          padding: 15px;
        }

        &.activated {
          color: $c-beige;
          border-right: 10px solid $c-beige;
          background-color: #0c2365;
        }
        &:active {
          outline: none !important;
        }
        &:hover {
          color: $c-beige;
          background-color: #0c2365;
        }
      }
    }
    .vflex2 {
      width: 100%;
      height: calc(100% - 50px);
      padding: 50px;
      overflow: scroll;
      background-color: $c-white;

      @media (max-width: 768px) {
        padding: 30px;
      }

      .logoAskQuestionStyle {
        position: absolute;
        bottom: 15px;
        right: 15px;
        width: 100px;
        height: auto;
        z-index: 9999;
        cursor: pointer;
      }
    }
  }
}
