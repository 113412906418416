.profile {
  display: flex;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  .profilepage_row1,
  .profilepage_row2 {
    width: 45%;

    @media (max-width: 1024px) {
      width: 100%;
    }
  }

  .user-chart-card {
    position: relative;
    padding: 20px 0;
    margin: 25px 0 30px;

    &__legend {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    &__user {
      position: relative;
      width: 190px;
      align-items: center;
      display: flex;
      margin: 0;
      font-size: 14px;
      color: $c-dark-gray;

      span {
        position: absolute;
        left: -40px;
        width: 30px;
        height: 3px;
        background-color: $c-beige;
      }
    }

    &__average-miyo {
      position: relative;
      width: 190px;
      align-items: center;
      display: flex;
      margin: 0;
      font-size: 14px;
      color: $c-dark-gray;

      span {
        position: absolute;
        left: -40px;
        width: 30px;
        height: 3px;
        background-color: $c-blue;
      }
    }

    &__average {
      position: relative;
      width: 190px;
      align-items: center;
      display: flex;
      margin: 0;
      font-size: 14px;
      color: $c-dark-gray;

      span {
        position: absolute;
        left: -40px;
        width: 30px;
        height: 3px;
        background-color: $c-beige;
      }
    }

    &__footer {
      display: flex;
      justify-content: space-between;

      @media (max-width: 768px) {
        flex-direction: column;
      }
    }

    &__y-label {
      position: absolute;
      line-height: 1;
      right: -30px;
      bottom: 100px;
      font-size: 12px;
    }

    &__x-label {
      font-size: 12px;
      line-height: 1;
    }
  }
}

.profilepage_formStyle {
  width: 100%;
  max-width: 450px;
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;

  .gender-dropdown-box {
    width: calc(100% - 20px);
    text-align: center;
    font-family: GalanoGrotesqueB;
    background-color: $c-white;
    display: flex;
    flex-direction: column;
    border: 1px solid $c-blue;

    div {
      cursor: pointer;
      color: $c-dark-gray;

      &:first-child {
        border-bottom: 1px solid $c-blue;
      }
    }
  }

  .profilepage-form-label {
    position: relative;
    margin: 0;
    width: 100%;
    text-align: center;
    font-weight: 600px;
    font-size: 20px;
    color: $c-blue;
    text-transform: uppercase;

    .gender-dropdown-button {
      position: absolute;
      cursor: pointer;
      font-size: 16px;
      padding-top: 5px;
      border-radius: 58px;
      background-color: $c-white;

      i {
        position: absolute;
        right: 0;
        color: $c-blue;
      }
    }
  }
  .profilepage_ButtonContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;

    button {
      margin: 15px;
    }
  }
  .text-input {
    padding: 5px;
    margin: 10px;
    width: 100%;
    height: 40px;
    border-radius: 35px;
    border: 1.5px solid $c-blue;
    text-align: center;
    background-color: $c-white !important;
    &:active,
    &:focus {
      outline: none;
    }
    &.error {
      border-color: $c-red;
    }
  }
  .input-feedback {
    color: $c-red;
  }
}

.profilepage_modalContainer {
  border-radius: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;

  .profilepage_modalContent {
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column !important;

    .profilepage_modalbody {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }
}

.profilepage_profileContainer {
  @media (max-width: 450px) {
    svg {
      height: 18px;
      width: 18px;
    }
  }
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 150px;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
  @media (max-width: 600px) {
    width: 80%;
    padding: 0;
  }
  @media (max-width: 520px) {
    width: 75%;
  }

  .profilepage_tooltip {
    height: 53%;
    top: 37px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    position: absolute;

    border-radius: 12px;
    width: 100%;
    z-index: 1;
    background-color: $c-white;
    $c-white-space: nowrap;
    @media (max-width: 1328px) {
      height: 36%;
      top: 73px;
    }
    @media (max-width: 1024px) {
      height: 34%;
      top: 45px;
    }
    @media (max-width: 389px) {
      height: 35%;
      top: 74px;
    }
    h1 {
      margin-bottom: 0px;

      font-size: 12px;

      color: #ff0000;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    h2 {
      margin-bottom: 0px;

      margin-right: 15px;

      border-radius: 50%;
      width: 22px;
      height: 22px;
      padding: 2px;
      background: $c-white;
      border: 2px solid $c-red;
      color: $c-red;
      text-align: center;

      font-size: 12px !important;
    }
  }

  .profilepage_row1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    @media (max-width: 580px) {
      padding: 0;
    }
    .profilepage_accountCard {
      width: 100%;
    }

    .profilepage_accountCard * {
      @media (max-width: 450px) {
        padding: 0.3% !important;
      }
    }
    .profilepage_profileCard {
      position: relative;

      .PorfileAlertUpdate * {
        font-size: 10px !important;
      }

      .PorfileAlertUpdate {
        background: url("../../images/miyo-bee-expression.svg");
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        height: 120px;
        width: 70px;
        z-index: 99;
        background-position: center;
        right: -122px;
        top: 107px;
        @media (max-width: 600px) {
          height: 139px;
          width: 74px;

          right: -129px;
          top: 103px;
        }
        @media (max-width: 575px) {
          height: 139px;
          width: 65px;

          right: -116px;
          top: 100px;
        }
        @media (max-width: 450px) {
          background: none;

          height: 150px;
          width: 90px;

          right: -135px;
          top: 79px;
        }
        h1 {
          position: absolute;
          top: -60px;
          font-size: 16px;
          color: $c-red;
          left: -6px;
          width: 80px;
          background-color: $c-white;
          padding: 10px;
          border-radius: 12px;
          font-weight: 500;

          @media (max-width: 575px) {
            top: -58px;

            left: -3px;
            width: 65px;
          }
          @media (max-width: 520px) {
            width: 49px;
          }
          @media (max-width: 450px) {
            top: -11px;
            left: -13px;
            width: 47px;
            background-color: $c-white;
            padding: 4px !important;

            font-weight: 500;
            font-size: 11px !important;
          }
          @media (max-width: 360px) {
            top: -13px;
            left: -29px;
            width: 40px;
            background-color: $c-white;
            padding: 4px !important;
            font-weight: 500;
            font-size: 10px !important;
          }
        }
        h2:nth-of-type(1) {
          position: absolute;
          top: -31px;
          left: -45px;
          border-radius: 50%;
          width: 22px;
          height: 22px;
          padding: 2px;
          background: $c-white;
          border: 2px solid $c-red;
          color: $c-red;
          text-align: center;

          font-size: 12px !important;

          @media (max-width: 450px) {
            top: -22px;
            left: -42px;
          }
          @media (max-width: 360px) {
            top: -22px;
            left: -46px;
          }
        }

        h2:nth-of-type(2) {
          position: absolute;
          top: 33px;
          left: -45px;
          border-radius: 50%;
          width: 22px;
          height: 22px;
          padding: 2px;
          background: $c-white;
          border: 2px solid $c-red;
          color: $c-red;
          text-align: center;

          font-size: 12px !important;

          @media (max-width: 450px) {
            top: 26px;
            left: -42px;
          }
          @media (max-width: 360px) {
            top: 26px;
            left: -46px;
          }
        }
        h2:nth-of-type(3) {
          border-radius: 50%;
          width: 22px;
          height: 22px;
          padding: 2px;
          background: $c-white;
          border: 2px solid $c-red;
          color: $c-red;
          text-align: center;
          /* font: 32px Arial, sans-serif; */
          font-size: 12px !important;

          position: absolute;
          top: 91px;
          left: -45px;
          @media (max-width: 450px) {
            top: 73px;
            left: -42px;
          }
          @media (max-width: 360px) {
            top: 65px;
            left: -46px;
          }
        }
        // i {
        //   position: absolute;
        //   top: 20px;
        //   left: -32px;
        // }
      }
    }

    .profilepage_profileCard * {
      @media (max-width: 450px) {
        padding: 0.5% !important;
      }
    }
  }
  .profilepage_row2 {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

//Linked Products

.linkedproductspage_container {
  display: flex;
  flex-direction: column;

  .linkedproductspage_dropDownContainer {
    width: 100%;
    margin: 0 0 35px;
    max-width: 400px;
    height: 100%;
    display: flex;
    flex-direction: column;

    .linkedproductspage_patientNameStyle {
      margin-right: 10px;
      color: $c-dark-gray;
      font-size: 12px;
    }
    .linkedproductspage_dropDownBox {
      position: absolute;
      width: 100%;
      max-width: 400px;
      color: $c-blue;
      font-size: 16px;
      border-radius: 0;
      overflow: hidden;
      border: 1.5px solid $c-blue;
      background-color: $c-white;

      .linkedproductspage_dropDownElements {
        padding: 10px;
        cursor: pointer;
        &:hover {
          background-color: $c-blue;
          color: $c-white;
        }
      }
    }
  }

  .linkedproductspage_dropDownActiveName {
    height: 40px;
    cursor: pointer;
    color: $c-blue;
    font-size: 14px;
    padding: 2.5px 10px;
    border-radius: 0;
    border: solid 1px $c-blue;
    background-color: $c-white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    i {
      margin-left: 10px;
    }
  }

  .linkedproductspage_dropdownAndaddnewOrder {
    width: 100%;
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .linkedproductspage_addOrderButton {
      text-align: right;
      margin-left: 20px;

      p {
        margin: 0;
        color: $c-blue;
        font-size: 14px;
        cursor: pointer;
        font-family: GalanoGrotesqueB;
      }
    }
  }

  .linkedproductspage_dropDownContainer {
    width: 100%;
    height: 100%;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
  }

  .linkedproductspage_cardAndPaginatorContainer {
    flex: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 20px;
    background-color: $c-white;
    border: none;
  }

  .linkedproductspage_cardContainer {
    display: flex;
    width: 100%;
    flex: 50%;
  }
}

//linked product details

.linkedproductdetailspage_container {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 70%;
  padding-left: 5%;
  padding-top: 3%;
  padding-bottom: 5%;
  @media (max-width: 768px) {
    width: 95%;
  }
  @media (max-width: 360px) {
    width: 100%;
    padding-right: 3%;
    padding-left: 3%;
  }
  .linkedproductdetailspage_backButton {
    color: $c-blue;
    font-size: 2vh;
    align-self: center;
    margin-right: 2.5%;
  }

  .linkedproductdetailspage_flex-v1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    > h1 {
      margin-right: 40px;
      @media (max-width: 490px) {
        font-size: 24px;
      }
    }
    .back-btn-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-right: 1rem;
      color: $c-blue;
      cursor: pointer;
      p {
        margin: 0;
        margin-left: 5px;
      }
      i {
        line-height: 1;
        display: flex !important;
        justify-content: center;
        align-items: center;
        margin-top: 2px;
      }
    }
  }
  .linkedproductdetailspage_flex-v2 * {
    @media only screen and (max-width: 383px) {
      font-size: 13px !important;
    }
    @media only screen and (max-width: 378px) {
      font-size: 12px !important;
    }
    @media only screen and (max-width: 361px) {
      font-size: 11px !important;
    }
    @media only screen and (max-width: 320px) {
      font-size: 10px !important;
    }
    @media only screen and (max-width: 280px) {
      font-size: 8px !important;
    }
  }
  .linkedproductdetailspage_flex-v2 {
    display: flex;
    flex: 1;
    flex-direction: row;
    background-color: $c-white;
    border-radius: 20px;
    height: 100%;
    overflow: hidden;

    .linkedproductdetailspage_flex-v2-h1 {
      display: flex;
      flex: 1;
    }
    .linkedproductdetailspage_flex-v2-h2 {
      display: flex;
      flex: 1;
    }
  }
}

//addnewordernumber

.new-order-number {
  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__back-btn {
    display: flex;
    align-items: center;
    cursor: pointer;

    p {
      margin: 0;
      color: $c-blue;
    }

    i {
      margin-right: 5px;
      color: $c-blue;
      display: flex !important;
    }
  }
}

.linkend-product-details {
  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__back-btn {
    display: flex;
    align-items: center;
    cursor: pointer;

    p {
      margin: 0;
      color: $c-blue;
    }

    i {
      margin-right: 5px;
      color: $c-blue;
      display: flex !important;
    }
  }

  &__item {
    display: flex;

    @media (max-width: 640px) {
      flex-direction: column;
    }
  }

  &__content {
    width: 50%;
    padding-right: 25px;
    border-bottom: 1px solid $c-blue;

    @media (max-width: 640px) {
      width: 100%;
    }
  }

  &__label {
    margin: 0;
    font-size: 14px;
    color: $c-dark-gray;
  }

  &__value {
    margin: 0;
  }

  &__info {
    display: flex;
    padding: 15px 0;
    border-bottom: 1px solid $c-blue;

    &--no-border {
      border: 0;
    }
  }

  &__info-wrapper {
    width: 50%;
  }
}

.linked-product {
  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__back-btn {
    display: flex;
    align-items: center;
    cursor: pointer;

    p {
      margin: 0;
      color: $c-blue;
    }

    i {
      margin-right: 5px;
      color: $c-blue;
      display: flex !important;
    }
  }

  &__lists {
    display: flex;
  }

  &__item {
    padding: 15px 5px 15px 0;
    width: 25%;
    border-bottom: 1px solid $c-blue;
  }

  &__label {
    margin: 0;
    font-size: 14px;
    color: $c-dark-gray;
  }

  &__value {
    margin: 0;
    color: $c-blue;
  }
}

.addnewordernumberpage_container {
  .addnewordernumberpage_flex-v1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 75%;
    align-items: center;
    @media (max-width: 1024px) {
      width: 100%;
    }
    h1 {
      @media (max-width: 400px) {
        font-size: 24px;
        width: 60%;
      }
    }
    .back-btn-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-right: 1rem;
      color: $c-blue;
      cursor: pointer;
      p {
        margin: 0;
        margin-left: 5px;
      }
      i {
        line-height: 1;
        display: flex !important;
        justify-content: center;
        align-items: center;
        margin-top: 2px;
      }
    }
  }
  .addnewordernumberpage_flex-v2 {
    width: 100%;
    max-width: 400px;
    background-color: $c-white;

    .btnExistingNewContainer {
      display: flex;
      flex-direction: row;
      width: 100%;
      @media (max-width: 1024px) {
        justify-content: flex-start;
      }
      .btnExistingNew {
        height: 40px;
        line-height: 15px;
        width: 45%;
        margin: 0 5px;
        max-width: 180px;
        min-width: 150px;
        padding: 10px;
        display: flex;
        justify-content: center;
        color: $c-white;
        border-radius: 30px;
        border: 1.5px solid $c-blue;
        background-color: $c-blue;
      }
    }
    .addnewordernumberpage_cardflexRow2Col1 {
      flex: 50%;
      display: flex;
      flex-direction: column;
    }
    .addnewordernumberpage_formStyle {
      .inputContainer {
        width: 100%;
        margin: 0 0 15px;

        .input-label {
          font-size: 14px;
          color: $c-dark-gray;
        }

        .text-input {
          width: 100%;
          height: 40px;
          border-radius: 0;
          border: 1.5px solid $c-blue;
          background-color: $c-white !important;

          &:active,
          &:focus {
            outline: none;
          }
        }
        .checkbox-label {
          margin-left: 10px;
          font-size: 14px;

          color: $c-blue;
        }
        .input-feedback {
          font-size: 12px;
          color: $c-red;
        }
      }
    }
    .addnewordernumberpage_dropDownContainer {
      position: relative;
      width: 100%;
      .addnewordernumberpage_dropDownActiveName {
        cursor: pointer;
        width: 100%;
        padding: 5px;
        height: 40px;
        font-size: 18px;
        color: $c-blue;
        border-radius: 0;
        border: 1.5px solid $c-blue;
        background-color: $c-white;
        justify-content: space-between;
        display: flex;
        align-items: center;
      }
    }
    .addnewordernumberpage_dropDownBox {
      position: absolute;
      top: 39px;
      left: 0;
      width: 100%;
      color: $c-blue;
      font-size: 14px;
      border-radius: 0;
      border: 1.5px solid $c-blue;
      background-color: $c-white;
      z-index: 999999999;
      overflow: hidden;
      .addnewordernumberpage_dropDownElements {
        padding: 10px;
        cursor: pointer;
        &:hover {
          background-color: $c-blue;
          color: $c-white;
        }
      }
    }
    .addnewordernumberpage_orderNumberInputContainer {
      display: flex;
      flex-direction: column;
      width: 50%;
    }
    .addnewordernumberpage_cardflexRow2 {
      display: flex;
      flex-direction: column;
    }

    .addnewordernumberpage_nameInputContainer {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
    .addnewordernumberpage_dobInputContainer {
      display: flex;
      flex-direction: column;
      flex: 50%;
    }
    .addnewordernumberpage_buttonStyle {
      font-size: 1.8vh;
      border-radius: 35px;
      margin-left: 5%;
      margin-top: 0.5%;
      width: 20%;
      padding: 0.5%;
      border: solid;
    }
    .addnewordernumberpage_buttonContainer {
      position: relative;
      display: flex;
      flex-direction: row;
      margin-top: 20px;
      z-index: 0;

      button {
        margin: 5px;
      }
    }
  }
}

//eyetesthistory
.eye-test-history {
  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__back-btn {
    display: flex;
    align-items: center;
    cursor: pointer;

    p {
      margin: 0;
      color: $c-blue;
    }

    i {
      margin-right: 5px;
      color: $c-blue;
      display: flex !important;
    }
  }

  &__title {
    font-size: 20px;
    margin: 20px 0 0;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    padding: 25px 0;
    border-bottom: 1px solid $c-blue;

    @media (max-width: 860px) {
      flex-direction: column;
    }
  }

  &__content-wrapper {
    display: flex;
    width: 65%;
    border-right: 1px solid $c-blue;

    @media (max-width: 860px) {
      width: 100%;
      border-right: 0;
      padding-bottom: 25px;
      border-bottom: 1px solid $c-blue;
    }
  }

  &__content {
    width: 33.33%;
    padding-right: 15px;
  }

  &__results-wrapper {
    width: 30% !important;

    @media (max-width: 860px) {
      padding-top: 25px;
      width: 100% !important;
    }
  }

  .eye-test-history__results-content {
    display: flex;
    padding-left: 15px;

    @media (max-width: 860px) {
      padding-left: 0;
    }

    p {
      width: 33.33%;
    }
  }

  &__label {
    margin: 0;
    font-size: 12px;
    color: $c-dark-gray;
  }
}

.eyetesthistorypage_container {
  display: flex;
  flex-direction: column;

  .table-header-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .back-btn-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-right: 1rem;
      color: $c-blue;
      cursor: pointer;
    }
  }
  .eyetesthistorypage_v2 {
    background-color: $c-white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
  }
  .eyetesthistorypage_cardsContainer * {
    @media (max-width: 333px) {
      padding: 1% !important;
    }
    @media (max-width: 308px) {
      padding: 0 !important;
    }
  }
  .eyetesthistorypage_cardsContainer {
    display: flex;
    flex-direction: row;
    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }

  .cardcomponent_hFlexbox {
    border-bottom: 0 !important;
  }

  .cardcomponent_container {
    margin-bottom: 0;

    &:last-child {
      padding-left: 30px;
      border-left: 1px solid $c-blue;
    }

    .cardcomponent_ContentContainer {
      .cardcomponent_hFlexbox {
        padding: 5px 0;
      }
    }
  }
}

//eyetestfullhistory

.eyetestfullhistorypage_container {
  position: relative;
  display: flex;
  flex-direction: column;

  .eyetestfullhistorypage_dropDownContainer {
    width: 100%;
    margin: 0 0 35px;
    max-width: 400px;
    height: 100%;
    display: flex;
    flex-direction: column;

    .eyetestfullhistorypage_patientName {
      margin-bottom: 5px;
      color: $c-dark-gray;
      font-size: 14px;
    }

    .eyetestfullhistorypage_dropDownBox {
      position: absolute;
      border-radius: 0;
      font-size: 16px;
      color: $c-dark-gray;
      z-index: 999;
      background-color: $c-white;
      border: 1px solid $c-blue;
      overflow: hidden;
      width: 100%;
      max-width: 400px;

      .eyetestfullhistorypage_dropDownElements {
        padding: 10px;
        cursor: pointer;

        &:hover {
          background-color: $c-blue;
          color: $c-white;
        }
      }
    }
  }

  .eyetestfullhistorypage_dropDownActiveName {
    height: 40px;
    padding: 2.5px 10px;
    color: $c-blue;
    cursor: pointer;
    border-radius: 0;
    background-color: $c-white;
    border: solid 1px $c-blue;
    display: flex;
    align-items: center;
    justify-content: space-between;

    i {
      margin-left: 10px;
    }
  }

  .eyetestfullhistorypage_v2 {
    padding: 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    background-color: $c-white;
    @media only screen and (max-width: 768px) {
      padding: 12px;
    }
  }
  .eyetestfullhistorypage_v3 {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-end;
  }
  .eyetestfullhistorypage_cardsContainer {
    display: flex;
    flex-direction: row;
    @media only screen and (max-width: 700px) {
      flex-direction: column;
    }
  }
  .eyetestfullhistorypage_cardsContainer * {
    @media only screen and (max-width: 380px) {
      font-size: 13px !important;
      padding: 1% !important;
    }
    @media only screen and (max-width: 330px) {
      font-size: 12px !important;
    }
    @media only screen and (max-width: 316px) {
      font-size: 11px !important;
    }
    @media only screen and (max-width: 310px) {
      font-size: 10px !important;
    }
  }
  .eyetestfullhistorypage_paginationIndexElement {
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    width: 25px;
    justify-content: center;
    display: flex !important;
    border-color: #154cff;
    background-color: #154cff;
    color: gray;
    border-radius: 50px;
    height: 25px;
    padding-top: 0px;
    &:active,
    &:hover {
      background-color: #154cff !important;
    }
  }

  .eyetestfullhistorypage_paginationIndexElement1 {
    height: 25px;
    margin-top: 2px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    width: 25px;
    justify-content: center;
    display: flex !important;
    border-color: #154cff;
    background-color: #154cff;
    color: gray;
    border-radius: 50px;
    padding-top: 0px;
    &:active,
    &:hover {
      background-color: #154cff !important;
    }
  }
  .eyetestfullhistorypage_nextPrevButtonStyle {
    border-radius: 20px !important;
    padding-top: 0px;
    padding-bottom: 2px;
    height: 89%;
    margin-top: 2px;
    margin-left: 2px;
    color: gray;
    border-color: #154cff;
  }
}

.pagination-container {
  .pagination {
    margin-top: 15px;
    justify-content: center;
    flex-wrap: wrap;
    li {
      width: 30px;
      height: 30px;
      min-width: 30px;
      margin: 5px;
      background-color: $c-white;
      border: 1px solid $c-blue;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &.active {
        background-color: $c-blue;
        a {
          color: $c-white;
        }
      }
      &.disabled {
        border: 1px solid $c-dark-gray;
        pointer-events: none;
      }
      &:hover {
        background-color: $c-blue;
        a {
          color: $c-white;
        }
      }
      a {
        color: $c-dark-gray;
        font-size: 12px;
        line-height: 1;
        text-decoration: none;
        user-select: none;
      }
      &.pagination-first-child,
      &.pagination-last-child {
        width: 75px;
        min-width: 75px;
        @media only screen and (max-width: 310px) {
          width: 60px;
          min-width: 60px;
        }
        @media only screen and (max-width: 278px) {
          width: 40px;
          min-width: 40px;
        }
      }
      @media only screen and (max-width: 500px) {
      }
    }
  }
}
