.rewardsconfirmation-container {
  .redemption-confirm {
    margin-bottom: 45px;
    border-bottom: 1px solid $c-blue;
  }

  .user-info-container {
    margin-top: 25px;
    padding-bottom: 25px;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid $c-blue;

    @media only screen and (max-width: 768px) {
      width: 100%;
    }
    p {
      margin-left: 0;
      width: 50%;
      line-height: 1.3;
      margin-bottom: 0;
      font-family: robotoM;
      color: #000000;
      &:first-child {
        margin-bottom: 10px;
      }
    }
  }

  .button-container {
    position: relative;
    margin-top: 55px;
    display: flex;
    z-index: 1;

    button {
      margin-right: 10px;
    }
  }

  .card-container {
    thead {
      tr {
        th {
          &:nth-child(2),
          &:nth-child(3) {
            text-align: center !important;
          }
        }
      }
    }

    tbody {
      td {
        padding: 10px 0;

        &:nth-child(2),
        &:nth-child(3) {
          text-align: center !important;
        }
      }
    }

    tfoot {
      td {
        padding: 15px 0 0;
        text-align: right;
      }
    }
  }
}
