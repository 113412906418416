body {
    margin: 0;
}

*:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 35px white inset;
}

::-webkit-scrollbar {
    height: 5px;
    width: 8px;
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: $c-dark-gray;
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px 0.5px 0px rgba(0, 0, 0, 0.75);
}
::-webkit-scrollbar-corner {
    background: transparent;
}

.text-input {
    width: 100%;
    height: 40px;
    padding: 5px;
    color: $c-blue;
    text-align: center;
    border-radius: 35px;
    font-family: GalanoGrotesqueB;
    border: 1.5px solid $c-blue;
    background-color: $c-white !important;

    &:active,
    &:focus {
        outline: none;
    }

    &::placeholder {
        color: $c-blue;
        font-weight: 800;
        text-transform: capitalize;
    }
}

.input-label {
    font-size: 14px;
    color: $c-black;
}

.input-feedback {
    margin-top: 5px;
    color: $c-red;
    font-size: 12px;
    font-family: GalanoGrotesqueB;
}

.title {
    font-size: 30px;
    font-weight: 800;
    color: $c-blue;
    text-transform: uppercase;
    font-family: GalanoGrotesqueB;
}

.description {
    margin: 10px 0;
    font-size: 20px;
    color: $c-black;
    text-align: center;
}

.exbold {
    font-weight: 900;
    font-family: GalanoGrotesqueExB;
}

.bold {
    font-weight: 900;
    font-family: GalanoGrotesqueB;
}

.semibold {
    font-family: GalanoGrotesqueB;
}

.light {
    font-family: GalanoGrotesqueL;
}
