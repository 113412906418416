.landing-page {
    .landing {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .landing-1 {
            width: 100%;
            background-color: $c-light-orange;

            &__main {
                display: flex;
                flex-direction: row;
                position: relative;
                align-items: center;
                justify-content: center;

                @include max-width($scr-md) {
                    flex-direction: column;
                }
            }

            &__left {
                padding-top: $margin-single-navbar;
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                @include max-width($scr-md) {
                    padding-top: 0px;
                    width: 100%;
                }
            }

            &__left-content {
                width: 75%;
                display: flex;
                flex-direction: column;
                font-size: $text-size-xxxl;
                font-family: GalanoGrotesqueB;
                color: $c-primary;
                line-height: 1.3;

                @include max-width($scr-md) {
                    padding-top: 50px;
                    padding-bottom: 50px;
                    justify-content: center;
                    align-items: center;
                }
            }

            &__left-content-bottom {
                padding-top: 15px;
                display: flex;
                flex-direction: row;
                width: 100%;

                @include max-width($scr-md) {
                    flex-direction: column;
                    align-items: center;
                }
            }

            &__left-content-bottom-button {
                transform: translate(10px, 4px);
                background-color: $c-primary;
                font-family: GalanoGrotesqueB;

                @include max-width($scr-md) {
                    transform: none;
                }
            }

            &__right {
                width: 50%;

                @include max-width($scr-md) {
                    width: 100%;
                }
            }

            &__center-circle {
                text-align: center;
                position: absolute;
                left: 0;
                right: 0;
                margin: 0 auto;
                bottom: 10%;
                display: flex;
                justify-content: center;

                @include max-width($scr-md) {
                    position: relative;
                    bottom: auto;
                    left: auto;
                    width: 75%;
                    padding-top: 20px;
                }
            }

            &__center-circle-container {
                width: 250px;
                height: 250px;
                border-radius: 50%;
                flex-shrink: none;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $c-white;
                padding: 35px 50px;
                box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);

                @include max-width($scr-md) {
                    width: 200px;
                    height: 200px;
                    margin: 0 auto;
                }
            }

            &__center-circle-text {
                font-family: GalanoGrotesqueB;
                color: $c-primary;
                line-height: 1.3;

                &--title {
                    font-size: $text-size-xxxxl;
                }

                &--subtitle {
                    line-height: 1;
                    font-size: $text-size-m;
                    font-family: GalanoGrotesqueSB;
                }
            }

            &__landing-bee {
                height: 200px;
                width: 161px;
                z-index: 9;

                @include max-width($scr-md) {
                    height: 150px;
                    width: 121px;
                }
            }
        }

        .landing-2 {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;

            &__main {
                display: flex;
                flex-direction: row;
                width: 88%;
                padding-top: 120px;
                padding-bottom: 30px;

                @include max-width($scr-md) {
                    flex-direction: column;
                    padding: 0px;
                    width: 75%;
                    align-items: center;
                    justify-self: center;
                }
            }

            &__left {
                width: 53%;
                @include max-width($scr-md) {
                    width: 75%;
                    padding-top: 50px;
                    padding-bottom: 20px;
                }
            }

            &__left-image {
                width: 100%;
            }

            &__right {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 47%;

                @include max-width($scr-md) {
                    width: 100%;
                    padding-top: 20px;
                    padding-bottom: 20px;
                }
            }

            &__right-wrap {
                width: 90%;
            }

            &__right-content {
                margin-bottom: 15px;
                display: flex;
                flex-direction: column;
            }

            &__right-text {
                &--title {
                    font-family: GalanoGrotesqueB;
                    font-size: $text-size-l;
                    margin-bottom: 15px;

                    @include max-width($scr-md) {
                        font-size: $text-size-m;
                    }
                }

                &--body {
                    font-family: GalanoGrotesqueR;
                    font-size: $text-size-m;
                    margin-bottom: 10px;
                    line-height: 1.4;

                    @include max-width($scr-md) {
                        font-size: $text-size-s;
                    }
                }

                &--last {
                    display: flex;
                    flex-direction: row;

                    @include max-width($scr-md) {
                        flex-direction: column;
                    }
                }
            }

            &__landing-bee {
                height: 200px;
                width: 230px;
                z-index: 9;
                margin-left: 20px;

                @include max-width($scr-lg) {
                    height: 190px;
                    width: 220px;
                }

                @include max-width($scr-md) {
                    height: 150px;
                    width: 180px;
                }

                @include max-width($scr-md) {
                    margin-top: 10px;
                    margin-left: auto;
                }
            }
        }

        .landing-3 {
            display: flex;
            flex-direction: row;
            width: 100%;
            background-color: $c-light-orange;
            padding-top: 150px;
            padding-bottom: 120px;

            @include max-width($scr-sm) {
                flex-direction: column;
                padding: 0px;
            }

            &__left {
                width: 50%;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                background-image: url(../../../images/landingBg3.png);

                @include min-width($scr-lg) {
                    width: 100%;
                    display: none;
                    background-image: none;
                }

                @include max-width($scr-md) {
                    width: 100%;
                    background-image: none;
                    display: none;
                }
            }

            &__left-image {
                display: none;

                @include min-width($scr-lg) {
                    width: 50%;
                    display: inline;
                }

                @include max-width($scr-md) {
                    display: initial;
                    width: 100%;
                }
            }

            &__right {
                width: 50%;
                background-color: $c-white;
                display: flex;
                align-items: center;
                justify-content: center;

                @include max-width($scr-md) {
                    width: 100%;
                    padding-top: 50px;
                    padding-bottom: 50px;
                }
            }

            &__right-container {
                display: flex;
                flex-direction: column;
                width: 75%;
                margin-top: 3vw;
                margin-bottom: 3vw;
            }

            &__right-text {
                &--title {
                    font-family: GalanoGrotesqueB;
                    font-size: $text-size-l;
                    margin-bottom: 15px;

                    @include max-width($scr-md) {
                        font-size: $text-size-m;
                    }
                }

                &--citation {
                    font-family: GalanoGrotesqueEL;
                    font-size: $text-size-xxs;
                    margin-bottom: 10px;
                }
            }

            &__right-body {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                padding-bottom: 10px;
            }

            &__right-body-image {
                width: 15%;
                padding: 10px;
            }

            &__right-body-text {
                font-family: GalanoGrotesqueR;
                font-size: $text-size-body;
                line-height: 1.5;

                @include max-width($scr-md) {
                    font-size: $text-size-s;
                }
            }
        }

        .landing-4 {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            text-align: center;
            background-color: $c-primary;

            &__main {
                flex-direction: column;
                padding-top: 120px;
                padding-bottom: 120px;
                width: 75%;

                @include max-width($scr-md) {
                    padding-top: 50px;
                    padding-bottom: 50px;
                }
            }

            &__text-title {
                font-family: GalanoGrotesqueB;
                font-size: $text-size-l;
                color: $c-white;
                margin-bottom: 30px;

                @include max-width($scr-md) {
                    font-size: $text-size-m;
                    margin-bottom: 15px;
                }
            }

            &__text-body {
                font-family: GalanoGrotesqueR;
                font-size: $text-size-m;
                color: $c-white;

                @include max-width($scr-md) {
                    font-size: $text-size-s;
                }
            }

            &__image-list {
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                justify-content: center;
                padding-top: 85px;
                padding-bottom: 45px;

                @include max-width($scr-md) {
                    flex-direction: column;
                    padding-top: 10px;
                    padding-bottom: 0px;
                    align-items: center;
                }
            }

            &__image {
                display: flex;
                flex-direction: column;
                margin: 20px;

                @include max-width($scr-md) {
                    width: 50%;
                    margin-top: 20px;
                    margin-bottom: 20px;
                }
            }

            &__image-image {
                width: 100%;
            }

            &__image-text {
                font-family: GalanoGrotesqueR;
                font-size: $text-size-m;
                color: $c-light-orange;
                margin-top: 20px;

                @include max-width($scr-sm) {
                    font-size: $text-size-s;
                }
            }
        }

        .landing-5 {
            width: 100%;
            display: flex;
            flex-direction: row;

            @include max-width($scr-sm) {
                flex-direction: column-reverse;
            }

            &__left {
                display: flex;
                width: 50%;
                background-color: $c-light-orange;
                justify-content: center;
                align-items: center;

                @include max-width($scr-sm) {
                    width: 100%;
                    padding-top: 50px;
                    padding-bottom: 50px;
                }
            }

            &__left-content {
                width: 75%;
                display: flex;
                flex-direction: column;
                padding: 3vw 0px 3vw 0px;

                @include max-width($scr-sm) {
                    padding: 0px;
                }
            }

            &__left-content-title {
                font-family: GalanoGrotesqueB;
                font-size: $text-size-l;
                margin-bottom: 30px;

                @include max-width($scr-sm) {
                    font-size: $text-size-m;
                    margin-bottom: 15px;
                }
            }

            &__left-content-body {
                font-family: GalanoGrotesqueR;
                font-size: $text-size-m;
                margin-bottom: 15px;

                &--last {
                    margin-bottom: 30px;
                }

                @include max-width($scr-sm) {
                    font-size: $text-size-s;
                }
            }

            &__left-content-answer {
                font-family: GalanoGrotesqueB;
                font-size: $text-size-m;
                color: $c-primary;
                margin-bottom: 30px;

                @include max-width($scr-sm) {
                    font-size: $text-size-s;
                    margin-bottom: 15px;
                }
            }

            &__left-content-bottom {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;

                @include max-width($scr-xxs) {
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
            }

            &__left-content-bottom-button {
                transform: translate(15px, 10px);
                background-color: $c-primary;
                font-family: GalanoGrotesqueB;

                @include max-width($scr-xxs) {
                    transform: none;
                }
            }

            &__landing-bee {
                height: 200px;
                width: 181px;
                z-index: 9;

                @include max-width($scr-lg) {
                    height: 190px;
                    width: 161px;
                }

                @include max-width($scr-sm) {
                    height: 150px;
                    width: 131px;
                }

                @include max-width($scr-xxs) {
                    margin-top: 15px;
                }
            }

            &__right {
                width: 50%;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                background-image: url(../../../images/landingBg4.png);

                @include min-width($scr-lg) {
                    width: 100%;
                    display: none;
                    background-image: none;
                }

                @include max-width($scr-sm) {
                    width: 100%;
                    background-image: none;
                    display: none;
                }
            }

            &__right-image {
                display: none;

                @include min-width($scr-lg) {
                    width: 50%;
                    display: inline;
                }

                @include max-width($scr-sm) {
                    display: initial;
                    width: 100%;
                }
            }
        }
    }
}
