.cardcomponent_container {
  width: 100%;
  margin-bottom: 35px;
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
  }

  .card {
    &__header {
      display: flex;
      align-items: center;
    }

    &__label {
      font-size: 12px;
      color: $c-dark-gray;
    }

    &__value {
      color: $c-black;
      font-family: GalanoGrotesqueB;
    }

    &__details-button {
      height: 16px;
      width: 16px;
      margin-left: 15px;
      color: $c-white;
      border-radius: 2px;
      background-color: $c-blue;
      display: flex !important;
      align-items: center;
      justify-content: center;
    }

    &__wrapper {
      padding: 15px 0;

      i {
        color: $c-blue;
      }
    }

    &__content {
      display: flex;

      span {
        width: 50%;
      }
    }

    &__item {
      width: 100%;
      padding-right: 10px;

      &:first-child {
        padding-left: 10px;
      }
    }

    &__edit-icon {
      margin-top: 0;
      align-self: flex-end;
      cursor: pointer;
    }
  }

  .cardcomponent_ContentContainer {
    width: 100%;
    padding-right: 20px;
    display: flex;
    flex-direction: column;

    .back-btn-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.75rem;
      margin-right: 2rem;
      color: $c-blue;
      cursor: pointer;
      p {
        margin: 0;
        margin-left: 5px;
      }
      i {
        line-height: 1;
      }
    }
    .cardcomponent_hFlexbox2 {
      display: flex;
      flex-direction: row;
      width: 100%;
      border-bottom: 1px solid $c-dark-gray;
      justify-content: space-between;
      &:hover {
        background-color: $c-blue;
        color: c-white !important;
        cursor: pointer;
        .cardcomponent_vflexboxElement {
          .cardcomponent_elementDescStyle {
            color: $c-white;
          }
          .cardcomponent_elementTitleStyle {
            color: lighten($c-blue, 50%);
          }
        }
      }
      &:last-child {
        border: 0;
      }
      .cardcomponent_element {
        width: 100%;
        height: 100%;
        flex-direction: column;
        display: flex;
        justify-content: space-between;
      }
      .cardcomponent_element2 {
        width: 100%;
        height: 100%;
        flex-direction: column;
        display: flex;
        justify-content: space-between;
        @media screen and (min-width: 825px) {
          padding-right: 14%;
        }
      }
      .cardcomponent_vflexboxElementLogoStyle {
        margin-right: 2%;
        margin-top: 1%;
      }
      .cardcomponent_vflexboxElement {
        display: flex;
        flex-direction: row;
        align-content: flex-start;
        justify-content: space-between;
        margin: 15px 0;
        width: 100%;
        .cardcomponent_elementTitleStyle {
          color: gray;
          font-size: 12px;
        }
        .cardcomponent_elementDescStyle {
          color: black;
          font-size: 14px;
          font-weight: 600;
          width: 100%;
        }
      }
      .cardcomponent_iconContainer {
        align-content: flex-start;
        display: flex;
        flex: 1;
        width: 100%;
        height: 100%;
        .cardcomponent_logoStyle {
          width: 100%;
          height: auto;
        }
      }
      .cardcomponent_vflexboxElementLogoStyle2 {
        display: flex;
        align-self: center;
        margin-left: 5%;
        width: 30px;
        height: 30px;
      }
    }
    .cardcomponent_hFlexbox {
      width: 100%;
      border-bottom: 1px solid $c-blue;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .cardcomponent_element {
        width: 100%;
        height: 100%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .cardcomponent_vflexboxElement {
        display: flex;
        flex-direction: row;
        align-content: flex-start;
        justify-content: space-between;
        width: 100%;
      }

      .cardcomponent_iconContainer {
        align-content: flex-start;
        display: flex;
        flex: 1;
        width: 100%;
        height: 100%;
        .cardcomponent_logoStyle {
          width: 100%;
          height: auto;
        }
      }
      .cardcomponent_vflexboxElementLogoStyle2 {
        padding: 10px;
        cursor: pointer;
        align-self: flex-end;
      }
    }
    .cardcomponent_navi {
      padding: 10px;
      font-size: 14px;
      color: $c-blue;
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
    }
  }
}
