.container-contact-us {
  padding-bottom: 50px;
  width: 100%;

  .contact-us-wrapper {
    display: flex;
    justify-content: space-between;

    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }

  .git_container {
    padding-right: 15px;
    width: 45%;

    @media (max-width: 1024px) {
      width: 100%;
    }
  }

  .cwu_container {
    width: 45%;

    @media (max-width: 1024px) {
      margin-top: 45px;
      width: 100%;
    }

    .sm_container {
      display: flex;

      a {
        width: 70px;
        height: 70px;
        display: block;
        margin-right: 15px;

        img {
          width: 100%;
        }
      }
    }
  }

  .contact-us-location {
    margin-top: 45px;
    width: 100%;

    .title {
      margin-bottom: 0;
    }

    .cwu_container {
      width: 100%;
      margin-top: 15px;
      border-bottom: 1px solid $c-blue;

      @media (max-width: 960px) {
        margin-top: auto;
      }
    }

    ul {
      padding: 0;

      li {
        display: flex;
        color: $c-dark-gray;
        justify-content: space-between;
        list-style-type: none;

        span {
          &.closed {
            color: $c-red;
          }
        }
      }
    }
  }

  .title {
    text-align: left;
  }

  .description {
    font-size: 18px;
    text-align: left;
    color: darken($c-dark-gray, 10%);

    b {
      font-family: GalanoGrotesqueSB;
    }

    a {
      color: $c-blue;
      text-decoration: underline;
      font-family: GalanoGrotesqueB;
    }
  }

  .msg_box {
    width: 100%;
    min-height: 200px;
    outline: none;
    resize: none;
    padding: 10px;
    margin-top: 10px;
    color: $c-blue;
    font-family: GalanoGrotesqueB;
    border: 1px solid $c-blue;

    &::placeholder {
      color: $c-blue;
    }
  }
}
