.verify-email-container {
  min-width: 325px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: $c-beige;

  .hoya-logo {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("../../images/headLogo.svg");

    @media (min-width: 1014px) {
      position: absolute;
      left: 30px;
      top: 0;
      width: 160px;
      height: 80px;
    }

    @media (max-width: 1014px) {
      position: relative;
      margin-top: 25px;
      width: 150px;
      height: 50px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .card-wrapper {
    position: relative;
    margin-top: auto;
    max-width: 640px;
    min-width: 325px;

    &:before {
      position: absolute;
      content: "";
      display: block;
      top: -15px;
      right: -15px;
      bottom: 0;
      left: 0;
      border-radius: 20px;
      background-color: $c-blue;
    }

    .title {
      padding: 25px;
      text-align: center;
    }
  }

  .verify-email-card {
    position: relative;
    width: 100%;
    min-width: 325px;
    max-width: 640px;
    height: 350px;
    border: 0;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    .loading-wrapper {
      z-index: 99999999;
    }
  }

  .button-container {
    margin-top: 20px;
  }

  .social-footer-flex {
    margin-top: auto;
  }
}
