.club-page{
    .club{
        .club-1{
            width: 100%;
            background-color: $c-light-orange;

            &__main {
                display: flex;
                flex-direction: row;

                @include max-width($scr-sm) {
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }
        
            }

            &__left{  
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                @include max-width($scr-sm) {
                    width: 100%;
                }
            }

            &__left-content{   
                width: 75%;
                display: flex; 
                flex-direction: column;
                font-size: $text-size-xxxl;
                font-family: GalanoGrotesqueB;
                color: $c-primary; 
                line-height: 1.3;             
                justify-content: center;
                align-items: center;

                @include max-width($scr-md) {
                    font-size: $text-size-xl;
                }

                @include max-width($scr-sm) {
                    padding-top: 50px;   
                }
            }

            &__right{          
                width: 50%;
            }

            &__right-wrap{          
                display: flex;
                width: 75%;
                align-items: center;
                justify-content: center;

                @include max-width($scr-sm) {
                    width: 100%;                
                }
            }

            &__right-image{
                width: 60%;
                padding-top: 100px;
                padding-bottom: 50px;

                @include max-width($scr-sm) {
                    width: 100%;
                    text-align: center;  
                    padding-top: 20px;
                    padding-bottom: 50px;                   
                }
            }
        }

        .club-2{
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            text-align: center;
            background-color: $c-primary;

            &__main{
                flex-direction: column;
                padding-top: 120px;
                padding-bottom: 120px;
                width: 75%;

                @include max-width($scr-sm) {
                    padding-top: 50px; 
                    padding-bottom: 50px;      
                }
            }

            &__text-title{
                font-family: GalanoGrotesqueB;
                font-size: $text-size-l;
                color: $c-white;

                @include max-width($scr-sm) {
                    font-size: $text-size-m;  
                    margin-bottom: 15px;  
                }
            }

            &__item-list{
                display: flex;
                flex-direction: row;
                padding-top: 50px;
                justify-content: center;

                @include max-width($scr-xs) {
                    flex-direction: column;
                    align-items: center;
                    padding-top: 15px; 
                }
            }

            &__item{
                display: flex;
                flex-direction: column;
                margin: 10px;
                padding: 20px;
                height: auto;
                width: 33.33%;
                background-color: $c-white;
                border-radius: 15px;
                align-items: center;  

                @include max-width($scr-xs) {  
                    width: 100%;            
                    margin: 10px 0px 20px 0px;   
                    justify-content: center;
                }
            }

            &__item-image{
                width: 80%;

                @include max-width($scr-xs) {  
                    width: 50%      
                }
            }

            &__item-title{
                font-family: GalanoGrotesqueSB;
                font-size: $text-size-l;
                margin-top: 20px;
                margin-bottom: 15px;

                @include max-width($scr-sm) {
                    font-size: $text-size-m;  
                    margin-bottom: 15px;  
                }
            }

            &__item-body{
                font-family: GalanoGrotesqueR;
                font-size: $text-size-body;
                margin-top: auto;
                margin-bottom: 40px;

                @include max-width($scr-sm) {
                    font-size: $text-size-s;    
                }

                @include max-width($scr-xs) {  
                    margin-bottom: 0px; 
                }
            }           
        }

        .club-3{
            display: flex;
            width: 100%;
            flex-direction: row;

            @include max-width($scr-sm) {
                flex-direction: column;
            }

            &__left{
                width: 50%;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                background-image: url(../../../images/clubBg2.png);

                @include min-width($scr-lg) {
                    width: 100%;
                    display: none;
                    background-image: none;
                }

                @include max-width($scr-sm) {
                    width: 100%;
                    background-image: none;
                    display: none;
                }
            }

            &__left-image{
                display: none;

                @include min-width($scr-lg) {
                    width: 50%;
                    display: inline;
                }
                
                @include max-width($scr-sm) {
                    display: initial;
                    width: 100%;
                }                
            }

            &__right{
                display: flex;
                width: 50%;
                align-items: center;
                justify-content: center;
                background-color: $c-white;

                @include max-width($scr-sm) {
                    width: 100%;
                }
            }

            &__right-content{
                width: 75%;
                margin-top: 8vw;
                margin-bottom: 8vw;
                position: relative;

                @include max-width($scr-sm) {
                    width: 75%;
                    margin-top: 50px;
                    margin-bottom: 50px;
                }
            }

            &__right-content-title{
                font-family: GalanoGrotesqueB;
                font-size: $text-size-l;
                line-height: 1.2;
                margin-bottom: 18px;
                color: $c-primary;
                position: absolute;
                left: -20px;

                @include max-width($scr-sm) {
                    font-size: $text-size-m;         
                }
            }

            &__right-content-body{
                margin-top: 50px;

                @include max-width("928px") {
                    margin-top: 70px;
                }

                @include max-width($scr-sm) {
                    margin-top: 50px;
                }

                @include max-width("349px") {
                    margin-top: 70px;
                }

                ul {
                    list-style: none;
                    margin-top: 0px;
                    margin-bottom: 0px;
                }

                ol {
                    list-style: none;
                    counter-reset: my-awesome-counter;
                    border-left: 2px solid $c-primary;
                    margin-bottom: 0px;
                }

                ol li {
                    position: relative;
                    counter-increment: my-awesome-counter;
                }
                
                ol li::before {
                    position: absolute;
                    content: counter(my-awesome-counter);
                    color: $c-white;
                    font-weight: bold;
                    background-color: $c-primary;
                    width: 40px;
                    height: 40px;
                    text-align: center;
                    align-items: center;
                    left: -60px;
                    padding: 8px;
                }          
            }

            &__right-content-body-title{
                font-family: GalanoGrotesqueB;
                font-size: $text-size-l;
                line-height: 1.2;
                color: $c-primary;

                @include max-width($scr-sm) {
                    font-size: $text-size-m;         
                }
            }

            &__right-content-body-body{
                font-family: GalanoGrotesqueR;
                font-size: $text-size-body;
                line-height: 1.5;
                padding-top: 18px;
                padding-bottom: 15px;

                @include max-width($scr-sm) {
                    font-size: $text-size-s;  
                }
            }

            &__right-content-button{
                background-color: $c-primary; 
                font-family: GalanoGrotesqueB;
                max-width: 320px;
                padding-left: 10px;
                padding-right: 10px;
            }
        }

    }
}