.rewardsRedemption-container {
  @media only screen and (max-width: 767px) {
    width: 90%;
    max-width: 90%;
    margin-left: 10px;
  }
  @media only screen and (max-width: 500px) {
    margin: 0;
    width: 100%;
    max-width: 100%;
  }
  @media only screen and (max-width: 400px) {
    margin: 0;
    padding: 0 !important;
  }

  .row-container {
    @media only screen and (max-width: 680px) {
      flex-direction: column;
    }

    .col-4 {
      @media (max-width: 680px) {
        max-width: 100%;
      }
    }

    .redemption-chart-container,
    .shipping-card-container,
    .redemption-summary-container {
      padding-right: 35px;
      margin-bottom: 35px;
      width: 100%;

      @media (max-width: 1024px) {
        padding-right: 0;
      }
    }

    .shipping-card-container {
      width: 100%;
      .shipping-details-card {
        border: 0;

        .sd-card-role {
          margin: 0;
          width: 100%;
          border-bottom: 1px solid $c-blue;
          pointer-events: none;
          &:last-child {
            border: 0;
          }
          .col {
            padding: 10px 0;
            pointer-events: auto;

            &.edit-col {
              display: flex;
              align-items: center;
              justify-content: center;
              color: $c-blue;
              cursor: pointer;
              pointer-events: auto;
            }
            .label {
              font-size: 14px;
              color: $c-dark-gray;
              margin: 0;
              @media only screen and (max-width: 380px) {
                font-size: 12px;
              }
            }
            .desc {
              margin: 0;
              font-size: 16px;
              @media only screen and (max-width: 380px) {
                font-size: 14px;
              }
            }
            .sd-details-icon-style {
              color: $c-blue;
            }
          }
          .col-1 {
            @media only screen and (max-width: 768px) {
              padding-right: 30px;
            }
          }
        }
      }
    }
  }
}

.redemption-summary-container {
  @media only screen and (max-width: 767px) {
    width: 128% !important ;
  }
  @media only screen and (max-width: 680px) {
    width: 100% !important ;
  }

  .title {
    font-size: 24px;
    margin-top: 5px;
  }

  .card-title {
    font-size: 20px;
  }
  .redemption-summary-card {
    padding: 0;
    border: 0;

    .rs-card-role :nth-child(2) {
      text-align: end;
    }
    .rs-card-role {
      margin: 0;
      color: $c-blue;
      width: 100%;

      .col {
        padding: 0;

        p {
          margin: 0;
        }
      }
      &.rs-card-miyo-points {
        padding-bottom: 0;
        padding-top: 15px;
        border-top: 1px solid $c-dark-gray;
        display: flex;
        flex-direction: column;
        .total-points-title {
          margin: 0;
          font-size: 20px;
        }
        .total-points {
          position: relative;
          margin: 0;
          display: inline-block;
          font-size: 55px;
          text-align: left;
          color: $c-dark-gray;
          border-bottom: 1px solid $c-blue;

          small {
            position: absolute;
            font-size: 40px;
          }
        }
      }
    }
  }
  .button-container {
    position: relative;
    padding: 20px 0;
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    z-index: 0;

    button {
      margin-right: 10px;
    }
  }
}

.redemption-chart-container {
  width: 100%;

  .card-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .card-title {
      font-size: 25px;
      color: $c-blue;
    }
    .back-btn-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.75rem;
      color: $c-blue;
      cursor: pointer;
      p {
        margin: 0;
        margin-left: 5px;
        font-family: GalanoGrotesqueB;
      }
      i {
        display: flex !important;
      }
    }
  }
  .redemption-chart-card {
    width: 100%;
    background-color: transparent;
    border: 0;
    height: 72%;
    .rc-card-header {
      color: $c-blue;
      font-size: 24px;
      width: 100%;
      margin: 0;

      @media only screen and (max-width: 410px) {
        font-size: 18px;
      }
    }

    .rc-card-header {
      .col {
        padding-left: 0;
        white-space: nowrap;
        font-family: GalanoGrotesqueB;
        border-bottom: 1px solid $c-blue;

        &:nth-child(2) {
          text-align: center;
        }

        &:nth-child(3) {
          text-align: center;
        }
      }
    }

    .rc-card-role {
      width: 100%;
      margin: 0;
      padding: 15px 0;

      border-bottom: 1px solid $c-blue;
      border-top: 1px solid $c-blue;

      pointer-events: none;

      .col {
        padding-left: 0;
        &:last-child {
          text-align: center;
        }
      }
      &:last-child {
        border-top: none;
      }

      .col {
        @media only screen and (max-width: 430px) {
          font-size: 16px;
        }
        @media only screen and (max-width: 360px) {
          font-size: 16px;
          padding: 0;
        }
      }
    }

    .points-text {
      font-family: GalanoGrotesqueB;
    }

    .label {
      margin: 0;
      font-size: 14px;
      color: $c-dark-gray;
      font-family: GalanoGrotesqueB;
    }

    .rc-col-text {
      color: $c-blue;
      font-size: 18px;
      margin-bottom: 0;

      @media only screen and (max-width: 430px) {
        font-size: 16px;
      }
    }
    .rc-removeButton {
      margin-top: 10%;
      margin-bottom: 0;
      color: gray;
      font-weight: 800;

      margin-left: 20px;
      cursor: pointer;
      pointer-events: auto;
    }
    .rc-button-container {
      padding-left: 0px;
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        cursor: pointer;
        color: $c-blue;
        font-size: 12px;
        text-decoration: underline;
        pointer-events: auto;
      }

      .rc-button-wrapper {
        display: flex;
        flex-direction: row;
        .rc-quantity-counter {
          color: $c-blue;
          font-size: 18px;
          font-weight: 550;
          margin-bottom: 0;
          border-width: 1.5px;
          padding: 0 15px;
          @media only screen and (max-width: 440px) {
            padding: 0px 10px;
          }
        }
        .rc-button {
          width: 30px;
          height: 30px;
          border-radius: 50px;
          display: flex;
          font-size: 18px;
          justify-content: center;
          align-content: center;
          color: $c-blue;
          border: 1px solid $c-blue;
          cursor: pointer;
          pointer-events: auto;
          &:hover {
            color: white;
            background-color: $c-blue;
          }
          @media only screen and (max-width: 440px) {
            padding-top: 2px;
            line-height: 1;
            width: 25px;
            height: 25px;
          }
        }
      }
    }
  }
}
