.carousel {
  position: relative;
  top: -82px;
  background-color: $c-beige;
  z-index: 0;

  .carousel-inner {
    width: 100%;
    max-width: 1366px;
    margin: 0 auto;

    .carousel-item {
      cursor: pointer;

      img {
        width: 100%;
      }
    }
  }
}

.carousel-pop-up {
  width: 100%;
  max-width: 640px;

  .miyosmart-pop-up-content {
    max-height: auto !important;

    .modal-body {
      .modal-title {
        padding-right: 5px;
      }

      .modal-share-container {
        width: 100%;
        justify-content: center;
        align-items: center;

        .modal-share-points {
          position: relative;
          color: $c-blue;
          font-size: 120px;
          line-height: 1;
          text-align: center;

          small {
            position: absolute;
            font-size: 50px;
            top: 0;
          }
        }

        .modal-share-desc {
          color: red;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 55%;
          height: 50px;
          margin: 0;
          text-align: center;
          font-size: 14px;
          font-weight: 700;
          @media (max-width: 450px) {
            font-size: 13px;
          }

          @media (max-width: 380px) {
            font-size: 12px;
          }
        }
        .modal_ShareOn {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: $c-blue;
          margin-left: 10px;

          img {
            margin: 5px;
            width: 55px;
            height: 55px;
            cursor: pointer;
          }
        }

        .share-popup-container {
          display: flex;
          justify-content: center;
          height: 100%;
          width: 100%;

          .share-popup-flexleft {
            display: flex;
            width: 30%;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url(../../images/miyo-bee-expression.svg);
          }

          .share-popup-flexright {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
        .dropdownContainerE {
          position: relative;
          align-self: center;
          color: #2f368f;
          font-weight: 600;
          .dropdownLogo {
            align-self: center;
            color: #2f368f;
            @media (max-width: 450px) {
              width: 25px;
              height: 25px;
            }
          }
          .dropdownOptionAndArrowContainerE {
            box-shadow: 6px 7px 16px 0px rgba(0, 0, 0, 0.75);
          }
          .dropdownTopArrowE {
            position: absolute;
            top: 155%;
            right: 0;
            margin-right: 20px;
            background: transparent;
            height: 0px;
            width: 0px;
            z-index: 1000;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid rgba(167, 169, 172, 0.623);
          }
          .dropdownOptionContainerE {
            position: absolute;
            top: 190%;
            right: 0;
            background: white;
            display: flex;
            flex-direction: column;
            z-index: 9999999999999;
            border: 1.5px solid rgba(128, 128, 128, 0.041);
            border-radius: 7px;
            .dropdownElementsE {
              display: flex;
              justify-content: flex-start;
              color: #2f368f;
              font-weight: 600;
              font-size: 16px;
              padding-top: 8px;
              padding-bottom: 8px;
              padding-left: 15px;
              padding-right: 40px;
              white-space: nowrap;
              &:hover {
                background-color: #2f368f;
                color: white;
              }
            }
          }
        }
      }
    }
  }
}
