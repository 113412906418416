.forget-password {
  width: 100%;
  min-height: 100vh;
  min-width: 325px;
  padding: 100px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background-color: $c-beige;

  @media (max-width: 1014px) {
    padding: 20px 0;
  }

  .hoya-logo {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("../../images/headLogo.svg");

    @media (min-width: 1014px) {
      position: absolute;
      left: 30px;
      top: 0;
      width: 160px;
      height: 80px;
    }

    @media (max-width: 1014px) {
      position: relative;
      width: 150px;
      height: 50px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__wrapper {
    position: relative;
    padding: 35px;
    width: 100%;
    min-height: 500px;
    z-index: 999;
    border-radius: 20px;
    background-color: $c-white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__title {
    margin-bottom: 20px !important;
  }

  .signUpCard {
    position: relative;
    width: 100%;
    min-height: 500px;
    max-width: 640px;

    &:before {
      position: absolute;
      content: "";
      display: block;
      top: -15px;
      right: -15px;
      bottom: 0;
      left: 0;
      border-radius: 20px;
      background-color: $c-blue;
    }

    @media (max-width: 1900px) {
      margin-top: 75px;
    }
    @media (max-width: 1600px) {
      margin-top: 55px;
    }

    .title {
      margin: 20px 0 0;
      text-align: center;
    }

    .registor-container {
      position: absolute;
      width: 150px;
      width: 150px;
      bottom: -40px;
      right: -80px;

      .miyobeeLogo {
        width: 100%;
      }
    }
  }

  .cardInputContainer {
    width: 100%;
    min-width: 320px;
    max-width: 460px;

    .loginForm,
    .reset-pass-form {
      text-align: center;
      .inputContainer {
        position: relative;
        margin: 15px;

        .icon_Container {
          position: absolute;
          right: 15px;
          top: 7px;
          width: 20px;
          height: 20px;
          cursor: pointer;

          img {
            width: 100%;
          }
        }
      }
    }
  }
}
