.topbar {
  position: relative;
  padding: 1rem 1.5rem;
  background-color: $c-beige;
  display: flex;
  justify-content: space-between;
  z-index: 9999999;

  @media (max-width: 640px) {
    flex-direction: column;
    justify-content: center;
  }

  .hoya-logo {
    width: 150px;
    height: 50px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("../../images/headLogo.svg");
  }

  .badge {
    position: absolute;
    width: 20px;
    height: 20px;
    right: -10px;
    top: -5px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    z-index: 0;
  }

  .landing-signup-button {
    text-align: center;
    .landingLoginSignupButtonStyle {
      color: white;
      background-color: black;
      border-radius: 18px;
      font-weight: 600;
      white-space: nowrap;
      margin-right: 10%;
      @media (max-width: 640px) {
        margin-top: 15px;
        margin-right: auto;
      }
    }
  }
  .navLeftsideElements {
    align-self: center;
  }

  .navRightSideElements {
    display: flex;
    flex-direction: row;
    @media (max-width: 640px) {
      margin-top: 15px;
      justify-content: center;
    }
    .RightSideElementstyleBell {
      cursor: pointer;
      margin: 0 15px;
      color: $c-blue;
      font-weight: 600;
      align-self: center;

      @media (max-width: 640px) {
        display: none;
      }

      .RightSideElementstyleBellWrapper {
        position: relative;
        width: 25px;
        height: 25px;
        cursor: pointer;

        img {
          position: relative;
          width: 100%;
          z-index: 99;
        }
      }
    }
    .RightSideElementstyleName {
      align-self: center;
      margin-left: 15px;
      margin-right: 15px;
      color: $c-blue;
      font-weight: 600;
      font-size: 14px;
      white-space: normal;
      word-break: break-all;
      line-height: 1;
    }

    .dropdownBellOptionContainer {
      position: absolute;
      width: 275px;
      right: 0;
      height: 174px;
      z-index: 1000;
      background-color: white;
      border: 1px solid $c-blue;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      @media (max-width: 1024px) {
        right: inherit;
      }
      .notification-list-wrapper {
        margin: 0;
        padding: 5px 10px;
        border-bottom: 1px solid $c-dark-gray;
        cursor: pointer;
        &.activated {
          background-color: darken(#fff, 5%);
        }
        &:last-child {
          border: 0;
        }
        .notification-msg {
          margin: 10px 0 5px;
          line-height: 1.2;
          font-size: 12px;
          color: $c-dark-gray;
        }
        .notification-btn {
          font-size: 12px;
          text-decoration: underline;
        }
      }
    }

    .dropdownContainer {
      cursor: pointer;
      position: relative;
      align-self: center;
      margin-right: 15px;
      color: $c-blue;
      font-weight: 600;
      .dropdownChevronLogo {
        margin-right: 16px;
        align-self: center;
        color: $c-blue;
      }

      .dropdownOptionContainer {
        position: absolute;
        top: 30px;
        right: 0;
        margin: 0;
        padding: 0;
        background: white;
        display: flex;
        flex-direction: column;
        z-index: 1000;
        border-radius: 0;
        border: 1.5px solid $c-blue;

        i {
          margin-right: 10px;
        }

        a {
          margin-top: -1px;
          margin-left: -1px;

          &:hover {
            text-decoration: none;
          }

          &:last-child {
            .dropdownElements {
              border-bottom: 0;
            }
          }
          .dropdownElements {
            color: $c-blue;
            font-weight: 600;
            font-size: 16px;
            padding: 8px 25px;
            white-space: nowrap;
            border-bottom: 1.5px solid $c-blue;
            cursor: pointer;

            &:hover {
              color: white;
              border-radius: 0;
              background-color: $c-blue;
            }
          }
        }
      }
      .transbutton {
        background-color: #ffff00;
      }
    }
  }
}
