/* -- Component -- */
$header-height: 70px;

/* -- Color -- */
$c-primary: #2e5da6;
$c-white: #ffff;
$c-light-orange: #f1d4a5;
$c-red: #e92e28;
$c-green: green;
$c-light-gray: rgba(255, 255, 255, 0.5);
$c-gray: gray;
$c-black: black;
$c-beige: #f1d4a5;
$c-blue: #095da7;
$c-gray: #e2e3e8;
$c-dark-gray: #a7a9ac;

/* -- Font size -- */
$text-size-xxxxl: 60px;
$text-size-xxxl: 45px;
$text-size-xxl: 32px;
$text-size-xl: 28px;
$text-size-l: 22px;
$text-size-m: 18px;
$text-size-body: 16px;
$text-size-s: 14px;
$text-size-xs: 12px;
$text-size-xxs: 10px;

/* -- Font weight -- */
$text-weight-strong: 900;
$text-weight-bold: 700;
$text-weight-medium: 500;
$text-weight-normal: 400;
$text-weight-light: 300;
$text-weight-thin: 100;

/* -- Media query -- */
$scr-lg: "1199px";
$scr-md: "991px";
$scr-sm: "767px";
$scr-xs: "679px";
$scr-xxs: "330px";

/* -- PageTopMargin Size -- */
$margin-single-navbar: 80px;
