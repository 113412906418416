.card-container {
  &.miyo-points-summary {
    .card {
      .card-body {
        .table {
          thead {
            th {
              &:last-child {
                text-align: right;
              }
            }
          }
          td {
            &:last-child {
              text-align: right;
            }
          }
        }
      }
    }
  }
  &.redemption-confirm {
    .card {
      .card-body {
        .table {
          thead {
            th {
              &:last-child {
                text-align: right;
              }
              &:first-child {
                text-align: left;
              }
            }
          }
          td {
            &:last-child {
              text-align: right;
            }
            &:first-child {
              text-align: left;
            }
          }
        }
      }
    }
  }
  .card-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .card-title {
      font-size: 25px;
      color: $c-blue;
    }
    .back-btn-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.75rem;
      margin-right: 2rem;
      color: $c-blue;
      cursor: pointer;

      p {
        margin: 0;
        margin-left: 5px;
        font-family: GalanoGrotesqueB;
      }
      i {
        display: flex !important;
      }
    }
  }
  .card {
    border: 0;
    border-radius: 0;
    overflow-y: scroll;

    @media (max-width: 600px) {
      overflow-x: scroll;
    }

    .card-body {
      padding: 0;
      max-height: 220px;

      .table {
        margin: 0;
        thead {
          th {
            padding-left: 0;
            border: 0;
            color: $c-blue;
            font-size: 18px;
            font-family: GalanoGrotesqueB;
            border-bottom: 1px solid $c-blue;
          }
        }
        td {
          padding-left: 0;
          font-size: 16px;
          color: $c-blue;
          &.edit {
            cursor: pointer;
          }
        }
        tfoot {
          tr {
            vertical-align: center;
            .title {
              font-size: 18px;
              color: $c-blue;
            }
            &:last-child {
              td {
                border: 0;
              }
            }
            td {
              vertical-align: middle;
            }
          }
        }
      }
      .pagination {
        margin-top: 15px;
        justify-content: center;
        flex-wrap: wrap;
        li {
          width: 30px;
          height: 30px;
          min-width: 30px;
          height: 30px;
          margin: 5px;
          background-color: $c-white;
          border: 1px solid $c-blue;
          border-radius: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          &.active {
            background-color: $c-blue;
            a {
              color: $c-white;
            }
          }
          &.disabled {
            border: 1px solid $c-dark-gray;
            pointer-events: none;
          }
          &:hover {
            background-color: $c-blue;
            a {
              color: $c-white;
            }
          }
          a {
            color: $c-dark-gray;
            font-size: 12px;
            line-height: 1;
            text-decoration: none;
            user-select: none;
          }
          &.pagination-first-child,
          &.pagination-last-child {
            width: 75px;
            min-width: 75px;
          }
        }
      }
    }
  }
}
