.testimonials-page{
   .testimonials{
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;

      .testimonials-1{
         display: flex;
         width: 100%;
         background-color: $c-light-orange;
         align-items: center;
         justify-content: center;

         &__main{
            width: 75%;
            padding-top: 150px;
            padding-bottom: 140px;

            @include max-width($scr-sm) {
               padding-top: 50px;   
               padding-bottom: 50px;
            }
         }

         &__main-title{
            font-family: GalanoGrotesqueB;
            font-size: $text-size-xxl;
            color: $c-primary;
            margin-bottom: 5px;

            @include max-width($scr-sm) {
               font-size: $text-size-l;
            }
         }

         &__main-body{
            display: flex;
            flex-direction: row;
            width: 100%;
            position: relative;

            @include max-width($scr-xs) {
               flex-direction: column;
            }
         }

         &__main-body-video{
            width: 56vw;
            height: 31.5vw;
            margin-right: 5px;

            @include max-width($scr-xs) {
              width: 100%;
              height: auto;
            }
         }

         &__main-body-video-wrap{
            width: 100%;
         }

         &__main-body-video-video{
            width: 100%;
            max-height: 30.5vw;
         }

         &__main-body-image-list{
            display: flex;
            flex-direction: column;
            width: 18vw;
            max-height: 30.5vw;
            overflow-y: auto;

            @include max-width($scr-xs) {
               width: 100%;
               height: auto;
               flex-direction: row;
               overflow-x: auto;
               justify-content: flex-start;
            }
         }

         &__main-body-image{
            cursor: pointer;
            width: 100%;
            margin-bottom: 0.375vw;
            filter: brightness(60%);
            max-height: 10.07vw;
            background-color: $c-light-orange;

            @include max-width($scr-xs) {
               width: 30%;
               height: auto;
               margin-bottom: 0px;
               margin-right: 5px;
            }
            
            &--selected{
               filter: brightness(100%);
               -webkit-transition: all 1s ease;
               -moz-transition: all 1s ease;
               -o-transition: all 1s ease;
               -ms-transition: all 1s ease;
               transition: all 1s ease;
            }

            &:last-child{
               margin-bottom: 0px;
            }
         }
      }

      .testimonials-2{
         display: flex;
         width: 100%;
         justify-content: center;
         align-items: center;
         background-color: $c-primary;

         &__main{
            width: 65%;
            padding-top: 120px;
            padding-bottom: 120px;

            @include max-width($scr-sm) {
               padding-top: 50px;
               padding-bottom: 50px
            }
         }

         &__main-title{
            text-align: center;
            font-family: GalanoGrotesqueB;
            font-size: $text-size-l;
            color: $c-white;
            margin-bottom: 50px;

            @include max-width($scr-sm) {
               font-size: $text-size-m;
               margin-bottom: 20px;
            }
         }

         .card-header{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            background-color: $c-white;
            border-bottom: none;
            
         }

         &__main-card-header-title{
            display: flex;
            flex-direction: column;
         }

         &__main-card-header-title-type{
            font-family: GalanoGrotesqueR;
            font-size: $text-size-xs;
            color: $c-gray;
            margin-bottom: 2px;         
         }

         &__main-card-header-title-title{
            font-family: GalanoGrotesqueB;
            font-size: $text-size-m;
            color: $c-primary;
            line-height: 1.2;
            border-bottom: 3px $c-primary solid;
            
            @include max-width($scr-sm) {
               font-size: $text-size-body;
            }
         }

         &__main-card-header-tooltip{
            width: 34px;
            height: 35px;
            justify-content: center;
            display: flex;
            align-items: center;
         }

         &__main-card-header-icon{
            width: 18px;
         }
         
         &__main-card-header-body{
            font-family: GalanoGrotesqueR;
            font-size: $text-size-m;

            @include max-width($scr-sm) {
               font-size: $text-size-s;
            }
         }

         .row{
            margin-bottom: 30px;

            @include max-width($scr-xs) {
               margin-bottom: 0px;
            }
         }

         .col-sm-6{
            @include max-width($scr-xs) {
               margin-bottom: 20px;   
            }            
         }

         .card{
            height: 100%;
            border-radius: 10px;
            cursor: pointer;
         }

         .card-footer{
            background-color: $c-white;
            border: none;
            justify-content: flex-end;
            align-items: flex-end;
            text-align: right;   
         }

         &__main-bottom{
            font-family: GalanoGrotesqueB;
            font-size: $text-size-m;
            color: $c-white;
            justify-content: center;
            text-align: center;
            align-items: center;
            margin-top: 50px;

            @include max-width($scr-sm) {
               font-size: $text-size-body;
               margin-top: 20px;
            }
         }

         &__main-bottom-button{
            margin-left: 2vw;
            width: 25px; 
            cursor: pointer;          
         }
      }

   }
}