@font-face {
    font-family: hvdClean;
    src: url("./../fonts/hvd_poster/hvd_poster_clean-webfont.woff2") format("woff2"), url("./../fonts/hvd_poster/hvd_poster_clean-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: hvd;
    src: url("./../fonts/hvd_poster/hvd_poster-webfont.woff2") format("woff2"), url("./../fonts/hvd_poster/hvd_poster-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: oswaldM;
    src: url("./../fonts/Oswald/oswald-medium-webfont.woff2") format("woff2"), url("./../fonts/Oswald/oswald-medium-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: oswaldR;
    src: url("./../fonts/Oswald/oswald-regular-webfont.woff2") format("woff2"), url("./../fonts/Oswald/oswald-regular-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: robotoM;
    src: url("./../fonts/Roboto/roboto-medium-webfont.woff2") format("woff2"), url("./../fonts/Roboto/roboto-medium-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: robotoR;
    src: url("./../fonts/Roboto/roboto-regular-webfont.woff2") format("woff2"), url("./../fonts/Roboto/roboto-regular-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: GalanoGrotesqueExB;
    src: url("./../fonts/galano_cufonfonts/galanogrotesqueblack-webfont.woff2") format("woff2");
    src: url("./../fonts/galano_cufonfonts/galanogrotesqueblack-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: GalanoGrotesqueB;
    src: url("./../fonts/galano_cufonfonts/galanogrotesquebold-webfont.woff2") format("woff2");
    src: url("./../fonts/galano_cufonfonts/galanogrotesquebold-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: GalanoGrotesqueSB;
    src: url("./../fonts/galano_cufonfonts/galanogrotesquealtsemibold-webfont.woff2") format("woff2");
    src: url("./../fonts/galano_cufonfonts/galanogrotesquealtsemibold-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: GalanoGrotesqueR;
    src: url("./../fonts/galano_cufonfonts/galanogrotesqueregular-webfont.woff2") format("woff2");
    src: url("./../fonts/galano_cufonfonts/galanogrotesqueregular-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: GalanoGrotesqueL;
    src: url("./../fonts/galano_cufonfonts/galanogrotesquelight-webfont.woff2") format("woff2");
    src: url("./../fonts/galano_cufonfonts/galanogrotesquelight-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: GalanoGrotesqueEL;
    src: url("./../fonts/galano_cufonfonts/galanogrotesqueextralight-webfont.woff2") format("woff2");
    src: url("./../fonts/galano_cufonfonts/galanogrotesqueextralight-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: GalanoGrotesqueT;
    src: url("./../fonts/galano_cufonfonts/galanogrotesquethin-webfont.woff2") format("woff2");
    src: url("./../fonts/galano_cufonfonts/galanogrotesquethin-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
