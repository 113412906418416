.rewardsDashboard-container {
  .rewards-category {
    .redemption-row {
      .product-card-container {
        max-width: 350px;
        min-width: 300px;

        .title {
          position: relative;
          color: $c-dark-gray;
          text-align: center;

          &:after {
            position: absolute;
            content: "pts";
            font-size: 25px;
            top: 15px;
            vertical-align: text-top;
            text-transform: lowercase;
          }
        }
      }

      .product-card-container {
        .miyoPoints-title {
          @media (max-width: 430px) {
            font-size: 32px;
          }
        }
      }
    }
  }
}
