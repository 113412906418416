.landing-home-page {
  .topbar {
    .navRightSideElements {
      .dropdownContainer {
        margin-right: 0 !important;

        .dropdownChevronLogo {
          margin-right: 0 !important;
        }
      }
    }
  }
}

.homepage_container {
  position: relative;
  width: 100%;

  .social-footer-flex {
    margin-top: 0;
  }

  .content_container {
    width: 100%;
    background-color: $c-blue;
    display: flex;
    justify-content: center;

    a {
      &:focus,
      &:hover {
        text-decoration: none;
      }
    }

    .menu-wrapper {
      width: 100%;
      height: 500px;
      max-width: 1024px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      @media (max-width: 560px) {
        flex-direction: column;
      }
      @media (max-width: 560px) {
        height: auto;
      }
      .icon_bg {
        width: 250px;
        height: 250px;
        margin: 15px 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 20px;
        text-align: center;
        font-size: 25px;
        color: $c-blue;
        background-color: $c-beige;

        img {
          width: 50%;
          margin-bottom: 20px;
        }

        &:hover {
          background-color: darken($c-beige, 8%);
        }
        @media (max-width: 960px) {
          width: 180px;
          height: 180px;
        }
        @media (max-width: 640px) {
          width: 150px;
          height: 150px;
        }
        p {
          margin: 0;
          font-family: GalanoGrotesqueSB;
        }
      }
    }
  }
}
