.product-card-container {
  width: calc(100% / 4 - 20px);
  margin: 10px;
  border-radius: 15px !important;
  border: 1px solid $c-blue !important;
  overflow: hidden;

  @media (max-width: 960px) {
    margin: 5px;
    width: calc(100% / 3 - 20px);
  }

  @media (max-width: 768px) {
    margin: 5px;
    min-width: 300px !important;
    width: calc(100% - 10px);
  }

  .title {
    font-size: 55px;
  }

  .product-image {
    height: 180px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    @media (max-width: 768px) {
      height: 150px;
    }
  }

  .product-content {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .product-card-button-container {
      width: 100%;
      text-align: center;
      .miyosmart-button {
        min-width: 100px;
      }
    }

    .title {
      font-size: 14px;
      font-family: GalanoGrotesqueR;
    }

    .card-subtitle {
      font-size: 12px;
      color: $c-dark-gray;
      font-family: GalanoGrotesqueSB;
    }
  }
}
