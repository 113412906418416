.navbar-wrap {
    .navbar-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9999999;

        @include max-width($scr-sm) {
            position: relative;
            background-color: $c-white;
        }

        button:focus {
            outline: none;
        }

        .navbar {
            font-family: GalanoGrotesqueB;
            color: $c-primary;
            text-transform: uppercase;

            @include max-width($scr-sm) {
                padding: 0px;
            }

            .button:focus {
                outline: none;
            }

            .navbar-collapse {
                @include max-width($scr-sm) {
                    width: 100%;
                    background-color: $c-primary;
                    color: $c-white;
                    padding: 0.5rem 1rem 1rem;
                    z-index: 999;
                }
            }

            .navbar-nav {
                align-items: center;

                @include max-width($scr-sm) {
                    padding-left: 1rem;
                    align-items: flex-start;

                    &:last-child li:last-child {
                        padding-top: 1rem;
                        border-top: 1px $c-white solid;
                        width: 100%;
                    }
                }
            }

            .nav-link {
                color: $c-primary;

                @include max-width($scr-sm) {
                    color: $c-white;
                }
            }

            .navbar-toggler {
                @include max-width($scr-sm) {
                    padding: 0.5rem 1rem;
                    border-color: transparent;

                    &:focus {
                        border-color: transparent;
                    }
                }
            }

            .navbar-toggler-icon {
                background-image: url("./../../images/navbar-list.png");
            }

            .collapse {
                .show {
                    .navbar__right-icon {
                        display: none;
                    }

                    .navbar__right-icon-selected {
                        display: inline;
                    }
                }
            }

            .dropdown-menu::before {
                position: absolute;
                top: -7px;
                right: 20px;
                display: inline-block;
                border-right: 7px solid transparent;
                border-bottom: 7px solid $c-white;
                border-left: 7px solid transparent;
                border-bottom-color: $c-white;
                content: "";
            }

            .dropdown-menu {
                padding: 0px;
                border: 0px;
                box-shadow: 2px 2px 5px $c-gray;
                -moz-box-shadow: 2px 2px 5px $c-gray;
                -webkit-box-shadow: 2px 2px 5px $c-gray;
                -o-box-shadow: 2px 2px 5px $c-gray;

                .dropdown-item {
                    font-family: GalanoGrotesqueB;
                    text-transform: uppercase;
                    color: $c-primary;
                    padding: 1rem 3.5rem;

                    &:active {
                        color: $c-white;
                        background-color: $c-primary;
                    }

                    &:last-child {
                        background-color: $c-primary;
                        color: $c-white;
                        border-bottom-left-radius: 0.25rem;
                        border-bottom-right-radius: 0.25rem;
                    }
                }
            }

            &__left-logo {
                height: 33px;
                margin: 0.5rem 1rem;

                @include max-width($scr-md) {
                    height: 20px;
                }
            }

            &__right-text {
                cursor: pointer;
                &:hover {
                    opacity: 0.8;
                }

                @extend .semibold;

                &--selected {
                    color: $c-white;
                    border-bottom: 1px solid $c-primary;

                    @include max-width($scr-sm) {
                        color: $c-light-orange;
                        border-bottom: none;
                    }
                }
            }

            &__right-icon {
                cursor: pointer;
                &:hover {
                    opacity: 0.8;
                }
            }

            &__right-icon-selected {
                display: none;
            }

            &__dropdown-icon {
                height: 25px;
                margin-right: 10px;
            }
        }
    }
}
