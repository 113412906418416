#overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
}
.apptEdit-container {
    form {
        width: 100%;
        .input-container {
            width: 100%;
            min-width: 200px;
            max-width: 400px;
            margin-top: 15px;
            overflow: hidden;
            .input-label {
                margin-top: 0;
                margin-bottom: 5px;
                font-size: 14px;
                color: $c-dark-gray;
            }
            input {
                padding: 5px;
                width: 100%;
                color: $c-blue;
                border-radius: 0;
                background-color: $c-white;
                border: 1px solid $c-blue;
                &:focus {
                    outline: none;
                }
            }
            .react-datepicker-wrapper {
                width: 100%;
            }
        }
        .input-button-container {
            margin-top: 25px;

            button {
                margin-right: 15px;
            }
        }
    }

    .datetime_container {
        margin-top: 25px;
        .input-label {
            margin: 0 0 5px;
            color: $c-dark-gray;
        }
        .icon_Container {
            padding: 0 5px;
            max-width: 400px;
            border: 1px solid $c-blue;
            display: flex;
            align-items: center;
            flex-direction: row-reverse;

            input {
                border: 0;
                padding: 0;
                color: $c-blue;
            }
        }
    }
}
.modal {
    .modal-dialog {
        &:before {
            position: absolute;
            content: "";
            top: -20px;
            left: 0;
            right: -20px;
            bottom: 0;
            border-radius: 20px;
            background-color: $c-blue;
        }

        .modal-content {
            padding: 25px;
            border-radius: 20px;
            min-height: 350px;
            max-width: 640px !important;

            .modal-body {
                .pop-up-card {
                    text-align: center;

                    i {
                        color: $c-blue;
                        margin-bottom: 35px;
                    }
                }
            }
        }
    }
}

.edit-appt-calendar {
    position: fixed;
    z-index: 100;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);

    &--linked-product {
        .react-calendar {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }

    .calendar-wrapper {
        position: relative;

        &:before {
            position: absolute;
            content: "";
            top: -20px;
            left: 0;
            right: -20px;
            bottom: 0;
            border-radius: 20px;
            background-color: $c-blue;
        }

        .appt-calendar {
            display: flex;

            @media (max-width: 600px) {
                flex-direction: column;
                align-items: center;
            }
        }
    }

    i:hover {
        .calendar_button {
            color: $c-blue;
            font-size: 25px;
            &:hover {
                background-color: $c-blue;
            }
        }
    }
    .react-calendar {
        position: relative;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        width: 100%;
        width: 500px;
        height: 410px;
        min-width: 325px;
        padding: 15px 30px;
        z-index: 1;
        border: 0;
        border-right: 1px solid $c-gray;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 600px) {
            width: 100%;
            max-width: 100%;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            border-bottom-left-radius: 0;
        }

        &__navigation {
            width: 100%;

            &__label {
                color: $c-blue;
                font-weight: 800;
                font-size: 20px !important;
                pointer-events: none;
                text-transform: uppercase;
                font-family: GalanoGrotesqueB;
            }
        }

        &__month-view__weekdays__weekday {
            padding: 5px 0 20px;
        }

        &__tile {
            padding: 0 !important;
            &:disabled {
                background-color: transparent;

                abbr {
                    color: darken($c-gray, 5%);
                }
            }

            &--active {
                &:after {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    width: 30px;
                    height: 30px;
                    display: block;
                    content: "";
                    background: $c-beige;
                    border-radius: 40px;
                    z-index: -10;
                }
            }

            &--hasActive {
                &:hover {
                    &:after {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        margin: auto;
                        width: 30px;
                        height: 30px;
                        display: block;
                        content: "";
                        background: $c-beige;
                        border-radius: 40px;
                        z-index: -10;
                    }
                }
            }
        }

        abbr {
            text-decoration: none;
            font-size: 14px;
            color: black;
            z-index: 999999;
        }

        button {
            position: relative;
            background-color: transparent !important;
            padding-left: 0;
            padding-right: 0;
            font-size: 16px;
            flex-basis: inherit;
            max-width: inherit;
            height: 46px;
            @media (max-width: 390px) {
                height: 36px;
            }
            &:hover,
            &:focus {
                &:after {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    width: 30px;
                    height: 30px;
                    display: block;
                    content: "";
                    background: $c-beige;
                    border-radius: 40px;
                    z-index: -10;
                }
            }
        }

        .react-calendar__navigation {
            pointer-events: none;
        }

        .react-calendar__navigation .react-calendar__navigation__arrow {
            background-color: $c-white;

            &:hover {
                background-color: $c-blue;
                color: $c-white;
                border-radius: 40px;
            }

            &:focus {
                outline: none;
            }
        }

        .react-calendar__navigation button {
            pointer-events: auto;
            cursor: pointer;

            &:hover {
                background-color: $c-white;
            }
        }

        .react-calendar__month-view__weekdays__weekday {
            &:nth-child(6) {
                color: $c-red;
            }
            &:last-child {
                color: $c-red;
            }
        }

        &__month-view__days__day--neighboringMonth {
            opacity: 0.3;
            color: $c-dark-gray;
            background-color: $c-white;
        }
    }

    .timeselect_container {
        background-color: white;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        z-index: 1;
        width: 100%;
        max-width: 250px;

        @media (max-width: 600px) {
            border-top-right-radius: 0;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            max-width: 100%;
        }

        .timeselect_title {
            display: grid;
            justify-content: center;
            text-align: center;
            padding: 20px;

            .title {
                font-size: 18px;
            }

            button {
                margin: 5px;
                padding: 10px 15px;
                border: none;
                color: black;
                font-size: 14px;
                font-weight: 800;
                border-radius: 20px;
                background-color: $c-white;

                &:focus {
                    outline: 0;
                }
                &.active {
                    background-color: $c-beige;
                }

                &:disabled {
                    opacity: 0.5;
                    text-decoration: line-through;
                    color: $c-dark-gray;
                    border: 1px solid $c-dark-gray;
                    background-color: $c-white;
                }
            }
        }
    }
}
