.rewardsRedemption-container {
  .row {
    margin: auto !important;
  }

  .row-container {
    .redemption-col {
      padding: 0;
      width: 60%;
      flex: auto;

      &:nth-child(2) {
        width: 40%;
        flex: auto;
      }
    }

    .redemption-header {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .back-btn-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: $c-blue;
        cursor: pointer;

        p {
          margin: 0;
          margin-left: 5px;
          font-family: GalanoGrotesqueB;
        }
        i {
          display: flex !important;
        }
      }
    }

    @media (max-width: 960px) {
      .redemption-col {
        width: 100% !important;
      }
    }
  }
}

.rredemption_modalContainer {
  top: 30%;
  transform: translate(50%, -50%);

  &:before {
    position: absolute;
    content: "";
    display: block;
    top: -15px;
    right: -15px;
    bottom: 0;
    left: 0;
    border-radius: 20px;
    background-color: $c-blue;
  }

  .rredemption_modalContent {
    height: 100%;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column !important;

    .form {
      width: 100%;
      padding: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .button-container {
        position: relative;
        width: 100%;
        margin-top: 15px;
        z-index: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;

        button {
          margin: 10px;
        }
      }
    }
  }
}
