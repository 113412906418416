.awards-page{
    .awards{
        .awards-1{
            width: 100%;
            background-color: $c-light-orange;

            &__main {
                display: flex;
                flex-direction: row;

                @include max-width($scr-sm) {
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }
        
            }

            &__left{  
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                @include max-width($scr-sm) {
                    width: 100%;
                }
            }

            &__left-content{   
                width: 75%;
                display: flex; 
                flex-direction: column;
                font-size: $text-size-xxxl;
                font-family: GalanoGrotesqueB;
                color: $c-primary; 
                line-height: 1.3;             
                align-items: center;

                @include max-width($scr-md) {
                    font-size: $text-size-xl;
                }

                @include max-width($scr-sm) {
                    padding-top: 50px;   
                    padding-bottom: 50px;   
                }
            }

            &__right{          
                width: 50%;

                @include max-width($scr-sm) {
                    width: 100%;
                }
            }
        }

        .awards-2{
            width: 100%;
            padding-top: 150px;
            padding-bottom: 120px;

            @include max-width($scr-sm) {
                padding: 0px;    
            }

            &__main{
                display: flex;
                flex-direction: row;
                width: 100%;
                background-color: $c-light-orange;
    
                @include max-width($scr-sm) {
                    flex-direction: column-reverse;
                }
            }

            &__left{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50%;
                position: relative;

                @include max-width($scr-sm) {
                    width: 100%;     
                }
            }

            &__left-image{
                height: 120%;
                top: -5%;
                position: absolute;

                @include max-width("1300px") {
                    width: 95%;
                    height: auto;
                    top: auto;
                }

                @include max-width($scr-sm) {
                    position: relative;
                    width: 50%;     
                }
            }

            &__right{
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: center;

                @include max-width($scr-sm) {
                    width: 100%;           
                }
            }

            &__right-container{
                display: flex;
                flex-direction: column;
                width: 75%;
                margin-top: 60px;
                margin-bottom: 20px;
            }

            &__right-title{
                font-family: GalanoGrotesqueB;
                font-size: $text-size-l;
                line-height: 1.2;
                margin-bottom: 18px;

                @include max-width($scr-sm) {
                    font-size: $text-size-m;         
                }

            }

            &__right-body{
                font-family: GalanoGrotesqueR;
                font-size: $text-size-m;
                line-height: 1.5;
                margin-bottom: 15px;

                @include max-width($scr-sm) {
                    font-size: $text-size-s;         
                }

            }
        }

        .awards-3{
            width: 100%;
            padding-top: 150px;
            padding-bottom: 120px;

            @include max-width($scr-sm) {
                padding: 0px;    
            }

            &__main{
                display: flex;
                flex-direction: row;
                width: 100%;
                background-color: $c-light-orange;
    
                @include max-width($scr-sm) {
                    flex-direction: column;
                }
            }

            &__left{
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: center;

                @include max-width($scr-sm) {
                    width: 100%;           
                }
            }

            &__left-container{
                display: flex;
                flex-direction: column;
                width: 75%;
                margin-top: 60px;
                margin-bottom: 20px;
            }

            &__left-title{
                font-family: GalanoGrotesqueB;
                font-size: $text-size-l;
                line-height: 1.2;
                margin-bottom: 18px;

                @include max-width($scr-sm) {
                    font-size: $text-size-m;         
                }

            }

            &__left-body{
                font-family: GalanoGrotesqueR;
                font-size: $text-size-m;
                line-height: 1.5;
                margin-bottom: 15px;

                @include max-width($scr-sm) {
                    font-size: $text-size-s;         
                }

            }
            
            &__right{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50%;
                position: relative;

                @include max-width($scr-sm) {
                    width: 100%;     
                }
            }

            &__right-image{
                height: 110%;
                top: -5%;
                position: absolute;

                @include max-width("1300px") {
                    width: 80%;
                    height: auto;
                    top: auto;
                }

                @include max-width($scr-sm) {
                    position: relative;
                    width: 40%;   
                    padding-bottom: 50px;  
                }
            }
        }

        .awards-4{
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            text-align: center;
            background-color: $c-primary;

            &__main{
                flex-direction: column;
                padding-top: 120px;
                padding-bottom: 120px;
                width: 60%;

                @include max-width("1300px") {
                    width: 80%;   
                }

                @include max-width($scr-sm) {
                    padding-top: 50px; 
                    padding-bottom: 50px;      
                }
            }

            &__text-title{
                font-family: GalanoGrotesqueB;
                font-size: $text-size-l;
                color: $c-white;
                margin-bottom: 30px;

                @include max-width($scr-sm) {
                    font-size: $text-size-m;  
                    margin-bottom: 15px;  
                }
            }

            &__text-body{
                font-family: GalanoGrotesqueR;
                font-size: $text-size-m;
                color: $c-white;

                @include max-width($scr-sm) {
                    font-size: $text-size-s;    
                }
            }

            &__item-list{
                display: flex;
                flex-wrap: wrap;   
                padding-top: 50px;
                justify-content: center;

                @include max-width($scr-xs) {
                    flex-direction: column;
                    align-items: center;
                    padding-top: 10px; 
                }
            }

            &__item{
                display: flex;
                flex-direction: column;
                padding: 20px;
                height: auto;
                width: 33.33%;

                @include max-width($scr-xs) {  
                    max-width: 100%;
                    width: 100%;            
                    margin: 10px 0px 20px 0px;   
                    justify-content: center;
                    align-items: center;      
                }
            }

            &__item-image{
                width: 100%;
                min-height: 50%;

                @include max-width($scr-xs) {  
                    width: 50%      
                }
            }

            &__item-text{
                font-family: GalanoGrotesqueSB;
                font-size: $text-size-m;
                color: $c-white;
                margin-top: 15px;
                margin-bottom: 40px;

                @include max-width($scr-sm) {
                    font-size: $text-size-s;    
                }

                @include max-width($scr-xs) {  
                    margin-bottom: 0px; 
                }
            }

            &__item-button-wrap{
                margin-top: auto;
            }

            &__item-button{
                color: $c-primary; 
                background-color: $c-white; 
                font-family: GalanoGrotesqueB;
                padding-top: 5px;
                padding-bottom: 5px;
            }

            &__bottom{
                font-family: GalanoGrotesqueB;
                font-size: 18px;
                color: $c-white;
                justify-content: center;
                text-align: center;
                align-items: center;
                margin-top: 40px;
            }

            &__bottom-button{
                margin-left: 2vw;
                width: 25px;
                cursor: pointer;
            }
        }
    }
}