.technology-page{
    .technology{
        .technology-1{
            width: 100%;
            background-color: $c-light-orange;

            &__main {
                display: flex;
                flex-direction: row;

                @include max-width($scr-sm) {
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }
        
            }

            &__left{  
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                @include max-width($scr-sm) {
                    width: 100%;
                }
            }

            &__left-content{   
                width: 75%;
                display: flex; 
                flex-direction: column;
                font-size: $text-size-xxxl;
                font-family: GalanoGrotesqueB;
                color: $c-primary; 
                line-height: 1.3;             
                justify-content: center;
                align-items: center;

                @include max-width($scr-md) {
                    font-size: $text-size-xl;
                }

                @include max-width($scr-sm) {
                    padding-top: 50px;   
                }
            }

            &__right{          
                width: 50%;
            }

            &__right-image{
                width: 90%;
                padding-top: 100px;
                padding-bottom: 50px;

                @include max-width($scr-sm) {
                    text-align: center;  
                    padding-top: 20px;
                    padding-bottom: 50px;                   
                }
            }
        }

        .technology-2{
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            padding-top: 100px;
            padding-bottom: 80px;

            @include max-width($scr-sm) {
                padding-top: 50px;
                padding-bottom: 50px;    
            }

            &__main{
                position: relative;
                display: flex;
                width: 75%;
                flex-direction: column;
                background-color: $c-light-orange;
                align-items: center;
                justify-content: center;
            }

            &__top{
                display: flex;
                width: 88%;
                align-items: center;
                justify-content: center;               
                padding-top: 50px;
                padding-bottom: 30px;
                border-bottom: 2px solid $c-black;

                @include max-width($scr-sm) {
                    padding-top: 30px;
                    padding-bottom: 30px;    
                }
            }

            &__top-wrap{
                display: flex;
                width: 85%;
                flex-direction: row;

                @include max-width($scr-sm) {
                    flex-direction: column;
                }
            }

            &__top-left{
                display: flex;
                width: 25%;
                justify-content: flex-start;
                align-items: center;

                @include max-width($scr-sm) {
                    width: 100%;
                    justify-content: center;
                    margin-bottom: 20px;
                }
            }

            &__top-left-image{
                width: 80%;

                @include max-width($scr-sm) {
                    width: 25%;
                }
            }

            &__top-right{
                width: 75%;
                margin: auto;

                @include max-width($scr-sm) {
                    width: 100%;
                }
            }

            &__top-right-title{
                font-family: GalanoGrotesqueB;
                font-size: $text-size-l;
                line-height: 1.2;
                margin-bottom: 18px;

                @include max-width($scr-sm) {
                    font-size: $text-size-m;         
                }
            }

            &__top-right-body{
                font-family: GalanoGrotesqueR;
                font-size: $text-size-m;
                line-height: 1.5;
                margin-bottom: 15px;

                @include max-width($scr-sm) {
                    font-size: $text-size-s;         
                }
            }

            &__bottom{
                display: flex;
                width: 88%;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                padding-top: 50px;
                padding-bottom: 50px;

                @include max-width($scr-sm) {
                    flex-direction: column;
                    padding-top: 20px;
                    padding-bottom: 20px;    
                }
            }

            &__bottom-left{
                display: flex;
                width: 40%;
                justify-content: center;   
                
                @include max-width($scr-sm) {
                    width: 60%;
                    padding-bottom: 30px;    
                }
            }

            &__bottom-left-image{
                width: 85%; 
                
                @include max-width($scr-sm) {
                    width: 100%;
                }
            }

            &__bottom-right{
                width: 60%;

                @include max-width($scr-sm) {
                    width: 100%;
                    border-top: 2px solid $c-black;
                    padding-top: 30px;    
                }
            }

            &__tech-bee{
                position: absolute;
                right: 0;
                bottom: 0;
                height: 200px;
                width: 151px;
                margin-right: 20px;

                @include max-width($scr-lg) {
                    height: 190px;
                    width: 131px;
                } 

                @include max-width($scr-md) {
                    height: 150px;
                    width: 101px;
                }

                @include max-width($scr-sm) {
                    position: relative;
                    align-self: flex-end;
                    height: 130px;
                    width: 97px;
                }

                @include max-width($scr-xxs) {
                   margin-top: 15px;
                }
            }
        }

        .technology-3{
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            padding-top: 100px;
            padding-bottom: 80px;
            background-color: $c-primary;

            @include max-width($scr-sm) {
                padding-top: 50px;
                padding-bottom: 50px;    
            }

            &__main{
                position: relative;
                display: flex;
                width: 75%;
                flex-direction: column;
                background-color: $c-light-orange;
                align-items: center;
                justify-content: center;
            }

            &__top{
                display: flex;
                width: 88%;
                align-items: center;
                justify-content: center;               
                padding-top: 50px;
                padding-bottom: 30px;
                border-bottom: 2px solid $c-black;

                @include max-width($scr-sm) {
                    padding-top: 30px;
                    padding-bottom: 30px;    
                }
            }

            &__top-wrap{
                display: flex;
                width: 85%;
                flex-direction: row;

                @include max-width($scr-sm) {
                    flex-direction: column;
                }
            }

            &__top-left{
                display: flex;
                width: 25%;
                justify-content: flex-start;
                align-items: center;

                @include max-width($scr-sm) {
                    width: 100%;
                    justify-content: center;
                    margin-bottom: 20px;
                }
            }

            &__top-left-image{
                width: 80%;

                @include max-width($scr-sm) {
                    width: 25%;
                }
            }

            &__top-right{
                width: 75%;
                margin: auto;

                @include max-width($scr-sm) {
                    width: 100%;
                }
            }

            &__top-right-title{
                font-family: GalanoGrotesqueB;
                font-size: $text-size-l;
                line-height: 1.2;
                margin-bottom: 18px;

                @include max-width($scr-sm) {
                    font-size: $text-size-m;         
                }
            }

            &__top-right-body{
                font-family: GalanoGrotesqueR;
                font-size: $text-size-m;
                line-height: 1.5;
                margin-bottom: 15px;

                @include max-width($scr-sm) {
                    font-size: $text-size-s;         
                }
            }

            &__bottom{
                display: flex;
                width: 95%;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding-top: 50px;
                padding-bottom: 50px;

                @include max-width($scr-sm) {
                    padding-top: 20px;
                    padding-bottom: 20px;    
                }
            }

            &__item-list{
                width: 90%;
                display: flex;
                flex-wrap: wrap;
            }

            &__item{
                display: flex;
                flex-direction: column;
                width: 20%;
                border-right: 1px dotted $c-black;
                align-items: center;
                justify-content: center;
                text-align: center;

                &:last-child{
                    border-right: none;
                }

                @include max-width($scr-sm) {
                    width: 50%;   
                    padding: 20px;
                    border-bottom: 1px dotted $c-black;


                    &:nth-child(even){
                        padding-right: none;
                        border-right: none;
                    }

                    &:last-child{
                        width: 100%;
                    }
                }

                @include max-width($scr-xxs) {
                    width: 100%;   
                    padding: 20px;
                    border-bottom: 1px dotted $c-black;
                    border-right: none;
                }
            }

            &__item-icon{
                width: 80%;
                margin-bottom: 20px;
            }

            &__item-label{
                font-family: GalanoGrotesqueSB;
                font-size: $text-size-m;
                line-height: 1.2;
                margin-top: auto;
                margin-left: 5px;
                margin-right: 5px;

                @include max-width($scr-sm) {
                    font-size: $text-size-s;         
                }
            }

            &__citation{
                max-width: 450px;
                font-family: GalanoGrotesqueEL;
                font-size: $text-size-xxs;
                align-self: flex-end;
                margin-top: 70px;
            }
        }

        .technology-4{
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            padding-top: 100px;
            padding-bottom: 80px;

            @include max-width($scr-sm) {
                padding-top: 50px;
                padding-bottom: 50px;    
            }

            &__main{
                position: relative;
                display: flex;
                width: 75%;
                flex-direction: column;
                background-color: $c-light-orange;
                align-items: center;
                justify-content: center;
            }

            &__top{
                display: flex;
                width: 88%;
                align-items: center;
                justify-content: center;               
                padding-top: 50px;
                padding-bottom: 30px;
                border-bottom: 2px solid $c-black;

                @include max-width($scr-sm) {
                    padding-top: 30px;
                    padding-bottom: 30px;    
                }
            }

            &__top-wrap{
                display: flex;
                width: 85%;
                flex-direction: row;

                @include max-width($scr-sm) {
                    flex-direction: column;
                }
            }

            &__top-left{
                display: flex;
                width: 25%;
                justify-content: flex-start;
                align-items: center;

                @include max-width($scr-sm) {
                    width: 100%;
                    justify-content: center;
                    margin-bottom: 20px;
                }
            }

            &__top-left-image{
                width: 70%;

                @include max-width($scr-sm) {
                    width: 25%;
                }
            }

            &__top-right{
                width: 75%;
                margin: auto;

                @include max-width($scr-sm) {
                    width: 100%;
                }
            }

            &__top-right-title{
                font-family: GalanoGrotesqueB;
                font-size: $text-size-l;
                line-height: 1.2;
                margin-bottom: 18px;

                @include max-width($scr-sm) {
                    font-size: $text-size-m;         
                }
            }

            &__top-right-body{
                font-family: GalanoGrotesqueR;
                font-size: $text-size-m;
                line-height: 1.5;
                margin-bottom: 15px;

                @include max-width($scr-sm) {
                    font-size: $text-size-s;         
                }
            }

            &__bottom{
                display: flex;
                width: 50%;
                flex-direction: row;
                justify-content: center;
                align-items: flex-start;
                text-align: center;
                padding-top: 30px;
                padding-bottom: 50px;

                @include max-width($scr-md) {
                    width: 70%;
                }

                @include max-width($scr-sm) {
                    padding-top: 20px;
                    padding-bottom: 20px; 
                    flex-direction: column;
                    align-items: center;      
                }
                
            }

            &__bottom-item{
                width: 50%;
                display: flex;
                flex-direction: column;

                &--first{
                    margin-right: 20px;      

                    @include max-width($scr-sm) {
                        margin-right: auto;
                        margin-bottom: 20px;
                    }
                }
                
                @include max-width($scr-sm) {
                    width: 100%;
                }
            }

            &__bottom-image{
                width: 100%;
                margin-bottom: 40px;         
                
                @include max-width($scr-sm) {
                    margin-bottom: 20px;    
                }

                @include max-width($scr-sm) {
                    width: 50%; 
                }
            }

            &__bottom-title{
                font-family: GalanoGrotesqueSB;
                font-size: $text-size-m;
                margin-bottom: 18px;

                @include max-width($scr-sm) {
                    font-size: $text-size-body;         
                }
            }

            &__bottom-body{
                font-family: GalanoGrotesqueR;
                font-size: $text-size-m;
                line-height: 1.5;

                @include max-width($scr-sm) {
                    font-size: $text-size-s;         
                }
            }

            &__tech-bee{
                position: absolute;
                left: 30px;
                bottom: -35px;
                height: 200px;
                width: 151px;

                @include max-width($scr-lg) {
                    height: 190px;
                    width: 131px;
                } 

                @include max-width($scr-md) {
                    height: 150px;
                    width: 101px;
                    left: 10px;
                }

                @include max-width($scr-sm) {
                    position: relative;
                    left: auto;
                    bottom: auto;
                }
            }
        }

        .technology-5{
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            padding-top: 100px;
            padding-bottom: 80px;
            background-color: $c-primary;

            @include max-width($scr-sm) {
                padding-top: 50px;
                padding-bottom: 50px;    
            }

            &__main{
                position: relative;
                display: flex;
                width: 75%;
                flex-direction: column;
                background-color: $c-light-orange;
                align-items: center;
                justify-content: center;
            }

            &__top{
                display: flex;
                width: 88%;
                align-items: center;
                justify-content: center;               
                padding-top: 50px;
                padding-bottom: 30px;
                border-bottom: 2px solid $c-black;

                @include max-width($scr-sm) {
                    padding-top: 30px;
                    padding-bottom: 30px;    
                }
            }

            &__top-wrap{
                display: flex;
                width: 85%;
                flex-direction: row;

                @include max-width($scr-sm) {
                    flex-direction: column;
                }
            }

            &__top-left{
                display: flex;
                width: 25%;
                justify-content: flex-start;
                align-items: center;

                @include max-width($scr-sm) {
                    width: 100%;
                    justify-content: center;
                    margin-bottom: 20px;
                }
            }

            &__top-left-image{
                width: 60%;

                @include max-width($scr-sm) {
                    width: 25%;
                }
            }

            &__top-right{
                width: 75%;
                margin: auto;

                @include max-width($scr-sm) {
                    width: 100%;
                }
            }

            &__top-right-title{
                font-family: GalanoGrotesqueB;
                font-size: $text-size-l;
                line-height: 1.2;
                margin-bottom: 18px;

                @include max-width($scr-sm) {
                    font-size: $text-size-m;         
                }
            }

            &__top-right-body{
                font-family: GalanoGrotesqueR;
                font-size: $text-size-m;
                line-height: 1.5;
                margin-bottom: 15px;

                @include max-width($scr-sm) {
                    font-size: $text-size-s;         
                }
            }

            &__bottom{ 
                display: flex;
                align-items: center;
                justify-content: center;      
                padding-top: 50px;
                padding-bottom: 200px;
                width: 100%;

                @include max-width($scr-sm) {
                    padding-top: 20px;
                    padding-bottom: 20px; 
                    flex-direction: column;
                    align-items: center;      
                }
                
            }

            &__bottom-main{
                width: 60%;
                display: flex;
                flex-direction: row;   
                
                @include max-width($scr-lg) {
                    width: 70%;
                }

                @include max-width($scr-md) {
                    width: 85%;
                }

                @include max-width($scr-xs) {
                    flex-direction: column;  
                    align-items: center;
                    justify-content: center;
                    width: 75%; 
                }
            }

            &__bottom-image{
                width: 35%;

                @include max-width($scr-xs) {
                    width: 40%; 
                    margin-bottom: 30px;
                }
            }

            &__bottom-item-list{
                width: 65%;
                display: flex;
                flex-direction: column;

                @include max-width($scr-xs) {
                    width: 100%; 
                }
            }

            &__bottom-item{
                display: flex;
                flex-direction: row;
                padding-bottom: 15px;

                &::before, ::after{
                    content: "";
                    flex: 1 1;
                    border-bottom: 2px solid #000;
                    margin: auto;
                }
            }

            &__bottom-item-content{
                width: 80%;
                margin-left: 10px;
            }

            &__bottom-title{
                font-family: GalanoGrotesqueSB;
                font-size: $text-size-m;
                line-height: 1.2;

                @include max-width($scr-sm) {
                    font-size: $text-size-m;         
                }
            }

            &__bottom-body{
                font-family: GalanoGrotesqueR;
                font-size: $text-size-m;
                line-height: 1.5;

                @include max-width($scr-sm) {
                    font-size: $text-size-s;         
                }
            }
            
            &__bottom-button-bee-container{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: flex-start;
                position: absolute;
                bottom: -50px;

                @include max-width($scr-sm) {
                    position: relative;
                    bottom: auto;
                }

                @include max-width($scr-xs) {
                    flex-direction: column;
                    align-items: center;
                }
            }

            &__bottom-button{
                transform: translate(15px, 15px);
                background-color: $c-primary; 
                font-family: GalanoGrotesqueB;
                max-width: none;
                min-width: 237px;
                padding-left: 20px;
                padding-right: 20px;

                @include max-width($scr-xs) {
                    transform: none;
                    min-width: 0px;
                    margin-bottom: 25px;
                }
            }

            &__tech-bee{
                z-index: 9;
                height: 180px;
                width: 141px;

                @include max-width($scr-lg) {
                    height: 160px;
                    width: 121px;
                } 

                @include max-width($scr-md) {
                    height: 150px;
                    width: 111px;
                }
            }
        }
    }
}